export const CURRENT_CLASS = 'kkksksla78scc8sc444';
// End //
export const PATH = 'asset:/';
export const IconsAndBackground = {
  homeBackground: 'asset:/backgrounds/bg_home.jpg',
  splashBackground: 'asset:/backgrounds/splash.jpg',
  topicBackground: 'asset:/backgrounds/bg_home.jpg',
  popupHeading: ['Background Music', 'Sounds', 'Setting'],
  popupImage: 'asset:/backgrounds/bg_setting_popup.png',
  backgroundMusic: 'asset:/raw/music_bg.mp3',
  soundIcon: ['asset:/icons/play_sound.png', 'asset:/icons/mute_sound.png'],
  musicIcon: ['asset:/icons/play_music.png', 'asset:/icons/mute_music.png'],
  backButton: 'asset:/icons/back_btn.png',
  videoButton: 'asset:/icons/video_btn.png',
  homeButton: 'asset:/icons/home.png',
  settingButton: 'asset:/icons/setting_btn_question.png',
  nextButton: 'asset:/icons/next_btn.png',
  tag: 'Zape Learning',
  title: 'Zape \n Learning',


  closeIcon: 'asset:/icons/false.png',
};

export const functionalities = [
  'multi-select',
  'single-select',
  'multi-selectfour',
  'single-select-two',
  'single-dragdrop',
  'lifecycle-four',
  'lifecycle-five',
  'count-object',
  'addsubtract-click',
  'count-click',
  'word-problems-click',
  'before-word'
];

let randomStructure = [
  { key: '1', levelName: 1, start: 1, end: 10, i: 0, numberOfQuestion: 10 },
  { key: '2', levelName: 2, start: 11, end: 20, i: 10, numberOfQuestion: 10 },
  { key: '3', levelName: 3, start: 1, end: 20, i: 20, numberOfQuestion: 10 },
  { key: '4', levelName: 4, start: 11, end: 30, i: 30, numberOfQuestion: 10 },
  { key: '5', levelName: 5, start: 21, end: 30, i: 40, numberOfQuestion: 10 },
  { key: '6', levelName: 6, start: 1, end: 30, i: 50, numberOfQuestion: 10 },
  { key: '7', levelName: 7, start: 11, end: 40, i: 60, numberOfQuestion: 10 },
  { key: '8', levelName: 8, start: 21, end: 40, i: 70, numberOfQuestion: 10 },
  { key: '9', levelName: 9, start: 31, end: 40, i: 80, numberOfQuestion: 10 },
  { key: '10', levelName: 10, start: 1, end: 40, i: 90, numberOfQuestion: 10 },
  { key: '11', levelName: 11, start: 11, end: 50, i: 100, numberOfQuestion: 10 },
  { key: '12', levelName: 12, start: 21, end: 50, i: 110, numberOfQuestion: 10 },
  { key: '13', levelName: 13, start: 31, end: 50, i: 120, numberOfQuestion: 10 },
  { key: '14', levelName: 14, start: 41, end: 50, i: 130, numberOfQuestion: 10 },
  { key: '15', levelName: 15, start: 1, end: 50, i: 140, numberOfQuestion: 10 },
];

export const grades = [
  //////////////////////////////////////////////////// KG /////////////////////////////////////////////////
  {
    grade: 'kg',
    gradeID: 'kg',
    backgroundHome: 'asset:/backgrounds/bg_home.jpg',

    subjects: [
      {
        subjectName: 'science',
        subjectID: 'science',
        subjectImage: 'asset:/buttons/science.png',
        categories: [
          {
            title: 'Plants and Animals',
            image: 'categories/science/5.png',
            key: '1',
            categoryId: '122',
            parent: '',

            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/1.png',
                text: 'Living Things',
                // key: living_thing,
                // functionality: 'multi-select',
                // questions: plantanimals,
                // level: level_living_things,
              },
              {
                key: '2',
                src: 'categories/science/sub-category/2.png',
                text: 'How Animal Adapt',
                functionality: 'single-dragdrop',
                // questions: animalAdopt,
              },
              {
                key: '3',
                src: 'categories/science/sub-category/3.png',
                text: 'Parts of Plants',
                functionality: 'lifecycle-four',
              },
              {
                key: '4',
                src: 'categories/science/sub-category/4.png',
                text: 'How Plants Adapt',
                functionality: 'single-dragdrop',
                // questions: plantAdopt,
              },
              {
                key: '65',
                src: 'categories/science/sub-category/5.png',
                text: 'Animal Homes',
                functionality: 'single-select',
              },
              {
                key: '6',
                src: 'categories/science/sub-category/6.png',
                text: 'Legs and Wings',
                functionality: 'single-dragdrop',
                // questions: legsWings,
              },
              {
                key: '7',
                src: 'categories/science/sub-category/7.png',
                text: 'Types of Animals',
                functionality: 'multi-select',
                // questions: questions,
              },
              {
                key: '8',
                src: 'categories/science/sub-category/8.png',
                text: 'Baby Animals',
                functionality: 'single-select',
                // questions: babyanimal,
              },
              {
                key: '9',
                src: 'categories/science/sub-category/9.png',
                text: 'Plant Lifecycle',
                functionality: 'lifecycle-five',
                // questions: lifeCycle,
              },
            ],
          },
          {
            title: 'Energy',
            image: 'categories/science/1.png',
            key: '2',
            parent: '',
            categoryId: '1221',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/energy/1.png',
                text: 'People or Fuel',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/energy/2.png',
                text: 'Sunshine and Melting',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/energy/3.png',
                text: 'Hot and Cold',
              },
              {
                key: '4',
                src: 'categories/science/sub-category/energy/4.png',
                text: 'Heat Energy',
              },
            ],
          },
          {
            title: 'Environment and Resources',
            image: 'categories/science/2.png',
            key: '3',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/environment-resources/1.png',
                text: 'Recycle',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/environment-resources/2.png',
                text: 'Reduce Reuse Recycle',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/environment-resources/3.png',
                text: 'Natural Resources',
              },
            ],
          },
          {
            title: 'Food and Nutrition',
            image: 'categories/science/3.png',
            key: '4',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/food/1.png',
                text: 'Fruits and Vegetable',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/food/2.png',
                text: '5 Food Groups',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/food/3.png',
                text: 'Healthy Food',
              },
            ],
          },
          {
            title: 'Forces',
            image: 'categories/science/4.png',
            key: '5',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/forces/1.png',
                text: 'Force and Movement',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/forces/2.png',
                text: 'Force and Direction',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/forces/3.png',
                text: 'Push and Pull',
              },
            ],
          },
          {
            title: 'Weather and Seasons',
            image: 'categories/science/6.png',
            key: '6',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/weather/1.png',
                text: 'Weather',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/weather/2.png',
                text: 'the 4 Seasons',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/weather/3.png',
                text: 'Severe Weather',
              },
            ],
          },
          {
            title: 'Our Bodies',
            image: 'categories/science/7.png',
            key: '7',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/bodies/1.png',
                text: 'our Senses',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/bodies/2.png',
                text: 'can you Sense it',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/bodies/3.png',
                text: 'Parts of Body',
              },
              {
                key: '4',
                src: 'categories/science/sub-category/bodies/4.png',
                text: 'Using our 5 Sense',
              },
            ],
          },
        ],
      },
      // MAth
      {
        subjectName: 'math',
        subjectImage: 'asset:/buttons/number.png',

        subjectID: 'math',

        categories: [
          {
            // title: 'Plants and Animals',
            image: 'categories/math/count_objects.png',
            key: '1',
            categoryId: '144',
            parent: '',
          },
          {
            // title: 'Energy',
            image: 'categories/math/more_less.png',
            key: '2',
            parent: '',
            categoryId: '17777',
          },
          {
            // title: 'Environment and Resources',
            image: 'categories/math/ordinal.png',
            key: '3',
            parent: '',
            categoryId: '33001100',
          },
          {
            // title: 'Food and Nutrition',
            image: 'categories/math/even_odd.png',
            key: '4',
            parent: '',
            categoryId: '55',
          },
          {
            // title: 'Forces',
            image: 'categories/math/correct_symbols.png',
            key: '5',
            parent: '',
            categoryId: '550055',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/missing_words.png',
            key: '6',
            parent: '',
            categoryId: '5055',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/what_comes.png',
            key: '7',
            parent: '',
            categoryId: '3321',
          },

          {
            // title: 'Plants and Animals',
            image: 'categories/math/skip_count.png',
            key: '8',
            categoryId: '8888',
            parent: '',
          },
          {
            // title: 'Energy',
            image: 'categories/math/count_back.png',
            key: '9',
            parent: '',
            categoryId: '001',
          },
          {
            // title: 'Environment and Resources',
            image: 'categories/math/least_greatest.png',
            key: '10',
            parent: '',
            categoryId: '484890',
          },
          {
            // title: 'Food and Nutrition',
            image: 'categories/math/addition.png',
            key: '11',
            parent: '',
            categoryId: '92478',
          },
          {
            // title: 'Forces',
            image: 'categories/math/substraction.png',
            key: '12',
            parent: '',
            categoryId: '1110011',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/comparison.png',
            key: '13',
            parent: '',
            categoryId: '0210',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/patterns.png',
            key: '14',
            parent: '',
          },

          {
            // title: 'Food and Nutrition',
            image: 'categories/math/bar_charts.png',
            key: '15',
            parent: '',
            categoryId: '11100111',
          },
          {
            // title: 'Forces',
            image: 'categories/math/time.png',

            key: '16',
            parent: '',
            categoryId: '010010111',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/word_problems.png',
            key: '17',
            parent: '',
            categoryId: '0100',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/true_false.png',
            key: '18',
            parent: '',
            categoryId: '09000',
          },
        ],
        // English
      },
      {
        subjectName: 'english',
        subjectImage: 'asset:/buttons/abc.png',
      },
      // URdu
      {
        subjectName: 'اردو',
        subjectImage: 'asset:/buttons/urdu.png',
      },

      // { categories: [] }
    ],
  },

  ////////////////////////////////////////////////////////////////// Grade 1  categories ////////////////////////////////////////////////////////////////////////////
  {
    grade: 'one',
    gradeID: 'one',
    backgroundHome: 'asset:/home/2.gif',

    subjects: [
      {
        subjectName: 'science',
        subjectID: 'science',
        subjectImage: 'asset:/buttons/science.png',
        categories: [
          //////////////////////////////////////////////////  Animals Topics  Grade 1 ////////////////////////////////////////////////////////
          {
            title: 'Animals',
            image: 'animal.png',
            key: '1',
            categoryId: '0001',
            parent: '',

            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/1.png',
                text: 'What Animals eat',
                // key: living_thing,
                // functionality: 'multi-select',
                // questions: plantanimals,
                // level: level_living_things,
              },
              {
                key: '2',
                src: 'categories/science/sub-category/2.png',
                text: 'Animal Habitats',
                functionality: 'single-dragdrop',
                // questions: animalAdopt,
              },
              {
                key: '3',
                src: 'categories/science/sub-category/3.png',
                text: 'Animals and their offsprings',
                functionality: 'lifecycle-four',
                // questions: plantsParts,
              },
              {
                key: '4',
                src: 'categories/science/sub-category/4.png',
                text: 'Animals adaptation',
                functionality: 'single-dragdrop',
                // questions: plantAdopt,
              },
              {
                key: '5',
                src: 'categories/science/sub-category/5.png',
                text: 'Legs and wings',
                functionality: 'single-select',
              },
              {
                key: '6',
                src: 'categories/science/sub-category/6.png',
                text: 'Aniamls Covering',
                functionality: 'single-dragdrop',
                // questions: legsWings,
              },
              {
                key: '7',
                src: 'categories/science/sub-category/7.png',
                text: 'Animals Lifecycle',
                functionality: 'multi-select',
                // questions: questions,
              },
              {
                key: '8',
                src: 'categories/science/sub-category/8.png',
                text: 'How Animals move',
                functionality: 'single-select',
                // questions: babyanimal,
              },
              {
                key: '9',
                src: 'categories/science/sub-category/9.png',
                text: 'Animal Homes',
                functionality: 'lifecycle-five',
                // questions: lifeCycle,
              },

              {
                key: '10',
                src: 'categories/science/sub-category/9.png',
                text: 'Ocean Forest farm desert habitats ',
                functionality: 'lifecycle-five',
                // questions: lifeCycle,
              },
              {
                key: '11',
                src: 'categories/science/sub-category/9.png',
                text: 'Plants eaters and meat eaters ',
                functionality: 'lifecycle-five',
                // questions: lifeCycle,
              },
              {
                key: '12',
                src: 'categories/science/sub-category/9.png',
                text: 'Types of Animals',
                functionality: 'lifecycle-five',
                // questions: lifeCycle,
              },
            ],
          },

          ///////////////////////////////////////////////////  Plants Topics Grade 1   /////////////////////////////////////////////////////////////////////////

          {
            title: 'plants',
            image: 'plant.png',
            key: '2',
            categoryId: '0002',
            parent: '',

            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/1.png',
                text: 'Plants and non livingthings',
                // key: living_thing,
                // functionality: 'multi-select',
                // questions: plantanimals,
                // level: level_living_things,
              },
              {
                key: '2',
                src: 'categories/science/sub-category/2.png',
                text: 'How Animal Adapt',
                functionality: 'single-dragdrop',
                // questions: animalAdopt,
              },
              {
                key: '3',
                src: 'categories/science/sub-category/3.png',
                text: 'Living Things',
                functionality: 'lifecycle-four',
                // questions: plantsParts,
              },
              {
                key: '4',
                src: 'categories/science/sub-category/4.png',
                text: 'Label the plants',
                functionality: 'single-dragdrop',
                // questions: plantAdopt,
              },
              {
                key: '5',
                src: 'categories/science/sub-category/5.png',
                text: 'Plants lifecycle',
                functionality: 'single-select',
              },
              {
                key: '6',
                src: 'categories/science/sub-category/6.png',
                text: 'Seeds',
                functionality: 'single-dragdrop',
                // questions: legsWings,
              },
            ],
          },

          ///////////////////////////////////////////////////  Energy Sound and light   /////////////////////////////////////////////////////////////////////////

          {
            title: 'Energy , sound , light',
            image: 'categories/science/1.png',
            key: '3',
            parent: '',
            categoryId: '1221',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/energy/1.png',
                text: 'People or Fuel',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/energy/2.png',
                text: 'Sunshine and Melting',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/energy/3.png',
                text: 'Hot and Cold',
              },
              {
                key: '4',
                src: 'categories/science/sub-category/energy/4.png',
                text: 'Heat Energy',
              },
            ],
          },
          {
            title: 'Environment and Resources',
            image: 'categories/science/2.png',
            key: '4',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/environment-resources/1.png',
                text: 'Recycle',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/environment-resources/2.png',
                text: 'Reduce Reuse Recycle',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/environment-resources/3.png',
                text: 'Natural Resources',
              },
            ],
          },
          {
            title: 'Food and Nutrition',
            image: 'categories/science/3.png',
            key: '5',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/food/1.png',
                text: 'Fruits and Vegetable',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/food/2.png',
                text: '5 Food Groups',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/food/3.png',
                text: 'Healthy Food',
              },
            ],
          },
          {
            title: 'Materials and properties',
            image: 'categories/science/4.png',
            key: '6',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/forces/1.png',
                text: 'Force and Movement',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/forces/2.png',
                text: 'Force and Direction',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/forces/3.png',
                text: 'Push and Pull',
              },
            ],
          },
          {
            title: 'Weather and Seasons',
            image: 'categories/science/6.png',
            key: '7',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/weather/1.png',
                text: 'Weather',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/weather/2.png',
                text: 'the 4 Seasons',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/weather/3.png',
                text: 'Severe Weather',
              },
            ],
          },
          {
            title: 'Our Bodies',
            image: 'categories/science/7.png',
            key: '8',
            parent: '',
            topic: [
              {
                key: '1',
                src: 'categories/science/sub-category/bodies/1.png',
                text: 'our Senses',
              },
              {
                key: '2',
                src: 'categories/science/sub-category/bodies/2.png',
                text: 'can you Sense it',
              },
              {
                key: '3',
                src: 'categories/science/sub-category/bodies/3.png',
                text: 'Parts of Body',
              },
              {
                key: '4',
                src: 'categories/science/sub-category/bodies/4.png',
                text: 'Using our 5 Sense',
              },
            ],
          },
        ],
      },

      // MAth
      {
        subjectName: 'math',
        subjectImage: 'asset:/buttons/number.png',

        subjectID: 'math',

        categories: [
          {
            // title: 'Plants and Animals',
            image: 'categories/math/count_objects.png',
            key: '1',
            categoryId: '144',
            parent: '',
          },
          {
            // title: 'Energy',
            image: 'categories/math/more_less.png',
            key: '2',
            parent: '',
            categoryId: '17777',
          },
          {
            // title: 'Environment and Resources',
            image: 'categories/math/ordinal.png',
            key: '3',
            parent: '',
            categoryId: '33001100',
          },
          {
            // title: 'Food and Nutrition',
            image: 'categories/math/even_odd.png',
            key: '4',
            parent: '',
            categoryId: '55',
          },
          {
            // title: 'Forces',
            image: 'categories/math/correct_symbols.png',
            key: '5',
            parent: '',
            categoryId: '550055',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/missing_words.png',
            key: '6',
            parent: '',
            categoryId: '5055',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/what_comes.png',
            key: '7',
            parent: '',
            categoryId: '010010',
          },

          {
            // title: 'Plants and Animals',
            image: 'categories/math/skip_count.png',
            key: '8',
            categoryId: '8888',
            parent: '',
          },
          {
            // title: 'Energy',
            image: 'categories/math/count_back.png',
            key: '9',
            parent: '',
            categoryId: '001',
          },
          {
            // title: 'Environment and Resources',
            image: 'categories/math/least_greatest.png',
            key: '10',
            parent: '',
            categoryId: '484890',
          },
          {
            // title: 'Food and Nutrition',
            image: 'categories/math/addition.png',
            key: '11',
            parent: '',
            categoryId: '92478',
          },
          {
            // title: 'Forces',
            image: 'categories/math/substraction.png',
            key: '12',
            parent: '',
            categoryId: '1110011',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/comparison.png',
            key: '13',
            parent: '',
            categoryId: '0210',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/patterns.png',
            key: '14',
            parent: '',
          },

          {
            // title: 'Food and Nutrition',
            image: 'categories/math/bar_charts.png',
            key: '15',
            parent: '',
            categoryId: '11100111',
          },
          {
            // title: 'Forces',
            image: 'categories/math/time.png',

            key: '16',
            parent: '',
            categoryId: '010010111',
          },
          {
            // title: 'Weather and Seasons',
            image: 'categories/math/word_problems.png',
            key: '17',
            parent: '',
            categoryId: '0100',
          },
          {
            // title: 'Our Bodies',
            image: 'categories/math/true_false.png',
            key: '18',
            parent: '',
            categoryId: '09000',
          },
        ],
        // English
      },
      // English
      {
        subjectName: 'english',
        subjectImage: 'asset:/buttons/abc.png',
      },
      // URdu
      {
        subjectName: 'اردو',
        subjectImage: 'asset:/buttons/urdu.png',
      },

      // { categories: [] }
    ],
  },

  ////////////////////////////////////////////////////////////////// Grade 2 ////////////////////////////////////////////////////////////////////////////
  {
    grade: 'Two',
    gradeID: 'Two',
  },

  // Science
];
export const questions = [
  ////////////////////////////////////////////////////////////////////KG Science //////////////////////////////////////////////////////////////////////////////////
  {
    grade: 'kg',

    subjects: [
      {
        subjectName: 'science',

        // Living Things
        categories: [
          {
            categoryName: 'Plants and Animals',
            categoryId: '122',
            topic: [
              {
                key: '1',
                topicName: 'Living Things',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'Select the living things',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        images: [
                          'asset:/star.png',
                          'asset:/problems/baby/butterfly.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '',
                        questionType: 'single-select-two',
                        title: 'Select the non-living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/baby/cublion.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 2,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'Select the living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/train.png',
                          'asset:/problems/eat/penguin.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'Select the non-living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/rock.png',
                          'asset:/problems/plants_animals/bee.jpg',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'Select the living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/icecream.png',
                          'asset:/problems/plants_animals/eagle.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'Select the non-living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/covering/cat.png',
                          'asset:/problems/power/melt/lego.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'Select the living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/quarter.png',
                          'asset:/6.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'Select the living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/7.png',
                          'asset:/problems/power/melt/wood.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },
                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'Select the non-living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/covering/peacock.png',
                          'asset:/problems/power/melt/marble.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'Select the living things',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        images: [
                          'asset:/9.png',
                          'asset:/problems/power/melt/lego.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },
                    ],
                  },



                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant1.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/time/1.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/7.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/star.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/9.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/5.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/2.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '8',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/hen.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title:
                          'Differentiate between livingthings and non-living things',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant11.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Living Things', , 'Non- Living Things'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },


                  {
                    key: '1',
                    levelName: 3,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 9,

                    questions: [
                      {
                        id: '1',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant1.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/monkey.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/cow.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant6.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant8.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/elephant.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant11.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/hen.jpg'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Differentiate between plants and animals',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant1.png'],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },


                  {
                    levelName: 4,
                    numberOfQuestion: 10,
                    questions: [
                      {
                        id: '1',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/camel.jpg',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        position: [1, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },
                      {
                        id: '2',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant5.png',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/alligator.jpg',

                        position: [1, 2],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },
                      {
                        id: '3',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/camel.jpg',
                        imgThree: 'asset:/problems/plants_animals/plant4.png',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        position: [1, 3, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },
                      {
                        id: '4',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/monkey.jpg',
                        imgTwo: 'asset:/problems/plants_animals/dog.jpg',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        position: [4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '5',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant8.png',
                        imgTwo: 'asset:/problems/plants_animals/fish1.png',
                        imgThree: 'asset:/problems/plants_animals/bird3.png',
                        imgFour: 'asset:/problems/plants_animals/plant9.png',

                        position: [1, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '6',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/insect1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/insect3.png',

                        position: [2, 3],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '7',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/cat.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        position: [2, 3, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '8',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/cat.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

                        position: [2, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '9',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant18.png',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

                        position: [1, 2, 3],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '10',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant16.png',

                        position: [1],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },
                    ],
                  },


                  {
                    levelName: 5,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant16.png',
                        imgFive: 'asset:/problems/plants_animals/hen.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant16.png',

                        position: [4, 6],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '2',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/elephant.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant17.png',
                        imgFive: 'asset:/problems/plants_animals/plant18.png',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        position: [4, 5],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '3',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant2.png',
                        imgThree: 'asset:/problems/plants_animals/plant3.png',
                        imgFour:
                          'asset:/problems/plants_animals/catterpiller.jpg',
                        imgFive: 'asset:/problems/plants_animals/bee.jpg',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        position: [1, 2, 3],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '4',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant2.png',
                        imgThree: 'asset:/problems/plants_animals/plant3.png',
                        imgFour:
                          'asset:/problems/plants_animals/catterpiller.jpg',
                        imgFive: 'asset:/problems/plants_animals/plant6.png',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        position: [1, 2, 3, 5],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '5',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/dog.jpg',
                        imgThree: 'asset:/problems/plants_animals/fish2.png',
                        imgFour: 'asset:/problems/plants_animals/plant3.png',
                        imgFive: 'asset:/problems/plants_animals/duck.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant5.png',

                        position: [4, 6],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '6',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant8.png',
                        imgThree: 'asset:/problems/plants_animals/fish2.png',
                        imgFour: 'asset:/problems/plants_animals/plant3.png',
                        imgFive: 'asset:/problems/plants_animals/duck.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant5.png',

                        position: [2, 4, 6],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '7',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant12.png',
                        imgTwo: 'asset:/problems/plants_animals/insect2.png',
                        imgThree: 'asset:/problems/plants_animals/fish3.png',
                        imgFour: 'asset:/problems/plants_animals/insect4.png',
                        imgFive: 'asset:/problems/plants_animals/fish2.png',
                        imgSix: 'asset:/problems/plants_animals/plant9.png',

                        position: [1, 6],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '8',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/insect2.png',
                        imgThree: 'asset:/problems/plants_animals/fish3.png',
                        imgFour: 'asset:/problems/plants_animals/fox.jpg',
                        imgFive: 'asset:/problems/plants_animals/fish2.png',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        position: [1],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '9',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/plant11.png',
                        imgThree: 'asset:/problems/plants_animals/plant12.png',
                        imgFour: 'asset:/problems/plants_animals/plant13.png',
                        imgFive: 'asset:/problems/plants_animals/plant15.png',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        position: [1, 2, 3, 4, 5],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },

                      {
                        id: '10',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/plant11.png',
                        imgThree: 'asset:/problems/plants_animals/cow.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant13.png',
                        imgFive: 'asset:/problems/plants_animals/dolphen.jpg',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        position: [1, 2, 4],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                      },
                    ],
                  },
                ],
              },

              // how animals adapt

              {
                key: '2',
                topicName: 'How Animal Adapt',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the Polar bear lives ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['desert', 'forest', 'artic ocean'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'How is the Arctic climate ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['warm', 'cold', 'hot'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'Polar bear have thick ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['ear', 'nose', 'fur'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'The fur keeps them ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['warm', 'healthy', 'cold'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'Which is the color of a polar bear?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['black', 'brown', 'white'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the camel lives ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['desert', 'forest', 'artic ocean'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'How is the desert climate ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['warm', 'cold', 'hot'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'What does the camel have on his back?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['ear', 'nose', 'hump'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'camels can store  _____  in their hump.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['food', 'water', 'fat'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'The fat turns into _____ and water.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['food', 'energy', 'water'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'what is the color of a camel?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['black', 'white', 'brown'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },

                  // level 3

                  {
                    key: '1',
                    levelName: 3,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the jiraaffe lives ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['grass-land', 'forest', 'artic ocean'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Jiraaffes are ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['carnivores', 'harbivores', 'omnivores'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'giraffes have a very long _____ ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['ear', 'neck', 'eyes'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title:
                          'giraffes can survive without _____ for a long period of time',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['food', 'water', 'meat'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'Giraffes are ?.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'cold-blooded',
                          'warm-blooded',
                          'hot-blooded',
                        ],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'Jiraffes is _____ animal in the world ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['shortest', 'tallest', 'smallest'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title:
                          'Giraffes have a log ____ neck with brown spots?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['white', 'brown', 'yellow'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },

              // parts of plants

              {
                key: '3',
                topicName: 'Parts of plants',
                level: [
                  // level 1

                  {
                    key: '3',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Choose the correct answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/flower.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['stem', 'root', 'flower'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'Choose the correct answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/stem.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['leaves', 'stem', 'flower'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'Choose the correct answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/leaves.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['leaves', 'stem', 'flower'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'Choose the correct answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/roots.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['leaves', 'root', 'flower'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // how plants adapt

              {
                key: '4',
                topicName: 'How Plants Adapt',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the water lilies live ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['air', 'soil', 'water'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'They need _____ from sun.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['air', 'light', 'energy'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'They have large , flat _______ .',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['flower', 'stem', 'leaves'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title:
                          'The leaves floaded on the  ________  in the sun.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['water', 'air', 'surface'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'Water lilies leaves has _______ color.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['black', 'brown', 'green'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the cactus found ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['desert', 'forest', 'artic ocean'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'How is the desert climate ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['warm', 'wet', 'dry'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Cactuses has very long deep _______ ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['stem', 'leaves', 'roots'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'They find ______ deep in ground.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['food', 'water', 'air'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'The color of the cactus is _______ .',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['white', 'green', 'blue'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // Legs and wings

              {
                key: '6',
                topicName: 'Legs and wings',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/8.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/eagle.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect4.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '5', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['5', '7', '2'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/spiderpic.png',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '5', '8'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/alligator.jpg',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '4', '8'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '8',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bird1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '4', '2'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '9',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/hen.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['2', '4', '3'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/butterfly.jpg',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['2', '4', '3'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },
                ],
              },

              // types of Animals

              {
                key: '7',
                topicName: 'Types of Animals ',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/star.png', 'asset:/star.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 0,
                      },

                      {
                        id: '',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/star.png', 'asset:/star.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 0,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/2.png', 'asset:/2.png'],
                        width: ['50%', '100%'],
                        height: ['50%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'Slect the small object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/3.png', 'asset:/3.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 2,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/4.png', 'asset:/4.png'],
                        width: ['50%', '100%'],
                        height: ['50%', '100%'],
                        answer: 1,
                      },
                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'Slect the small object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/5.png', 'asset:/5.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 2,
                      },
                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/6.png', 'asset:/6.png'],
                        width: ['50%', '100%'],
                        height: ['50%', '100%'],
                        answer: 1,
                      },
                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/7.png', 'asset:/7.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 2,
                      },
                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/8.png', 'asset:/8.png'],
                        width: ['50%', '100%'],
                        height: ['50%', '100%'],
                        answer: 1,
                      },
                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'Slect the big object',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: ['asset:/9.png', 'asset:/9.png'],
                        width: ['100%', '50%'],
                        height: ['100%', '50%'],
                        answer: 1,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect3.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bird4.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect2.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bird3.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/owl.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '7',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bird2.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '8',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect1.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '9',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/butterfly.png',
                        ],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect4.png'],

                        suggestions: ['Bird', , 'Ant'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },

                  // level 3
                  {
                    key: '1',
                    levelName: 3,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 9,

                    questions: [
                      {
                        id: '1',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant1.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/monkey.jpg'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/cow.jpg'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant6.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant8.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/elephant.jpg'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant11.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/hen.jpg'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/plant1.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Plant', , 'Animal'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },

                  // Leve 4
                  {
                    levelName: 4,
                    numberOfQuestion: 10,
                    questions: [
                      // {
                      //   id: '1',
                      //   title: 'Polar bear lives in artic ocean?',
                      //   questionType: 'dragdrop-one',
                      //   images: ['asset:/backgrounds/question/science/howanimalsadapt/bear.png'],
                      //   suggestionOne: 'Warm',
                      //   suggestionTwo: 'Cold',
                      //   suggestions: ['warm', 'cold','normal'],
                      //   suggestionBackgrounds: [
                      //     'red',
                      //     'green',
                      //     'blue',
                      //     'orange',
                      //   ],
                      //   answer: 0,
                      // },
                      // {
                      //   id: '2',

                      //   questionType: 'single-select',
                      //    title: 'Select the animal house',

                      //   object: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgTwo: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
                      //   answer: 2,
                      // },

                      {
                        id: '1',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/camel.jpg',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        postion: [1, 4],
                      },
                      {
                        id: '2',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant5.png',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/alligator.jpg',

                        postion: [1, 2],
                      },
                      {
                        id: '3',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/camel.jpg',
                        imgThree: 'asset:/problems/plants_animals/plant4.png',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        postion: [1, 3, 4],
                      },
                      {
                        id: '4',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/monkey.jpg',
                        imgTwo: 'asset:/problems/plants_animals/dog.jpg',
                        imgThree: 'asset:/problems/plants_animals/bee.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        postion: [4],
                      },

                      {
                        id: '5',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant8.png',
                        imgTwo: 'asset:/problems/plants_animals/fish1.png',
                        imgThree: 'asset:/problems/plants_animals/bird3.png',
                        imgFour: 'asset:/problems/plants_animals/plant9.png',

                        postion: [1, 4],
                      },

                      {
                        id: '6',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/insect1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/insect3.png',

                        postion: [2, 3],
                      },

                      {
                        id: '7',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/cat.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/plant2.png',

                        postion: [2, 3, 4],
                      },

                      {
                        id: '8',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/cat.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

                        postion: [2, 4],
                      },

                      {
                        id: '9',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/plant18.png',
                        imgTwo: 'asset:/problems/plants_animals/plant12.png',
                        imgThree: 'asset:/problems/plants_animals/plant11.png',
                        imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

                        postion: [1, 2, 3],
                      },

                      {
                        id: '10',
                        title: 'Select all the Plants ',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant16.png',

                        postion: [1],
                      },

                      // {
                      //   id: '3',
                      //   title: 'Select all the living things ',
                      //   questionType: 'multi-select',
                      //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgTwo: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgFour: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgFive: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgSix: 'asset:/backgrounds/background_before_home.jpg',

                      //   postion: [1, 2, 4, 6],
                      // },

                      // {
                      //   id: '4',
                      //   title: 'Select all the Plants ',
                      //   questionType: 'multi-selectfour',
                      //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgTwo:'asset:/backgrounds/background_before_home.jpg',
                      //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
                      //   imgFour: 'asset:/backgrounds/background_before_home.jpg',

                      //   postion: [1, 4],
                      // },
                    ],
                  },

                  // Level 5
                  {
                    levelName: 5,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant16.png',
                        imgFive: 'asset:/problems/plants_animals/hen.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant16.png',

                        postion: [4, 6],
                      },

                      {
                        id: '2',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/elephant.jpg',
                        imgTwo: 'asset:/problems/plants_animals/fish3.png',
                        imgThree: 'asset:/problems/plants_animals/hen.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant17.png',
                        imgFive: 'asset:/problems/plants_animals/plant18.png',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        postion: [4, 5],
                      },

                      {
                        id: '3',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant2.png',
                        imgThree: 'asset:/problems/plants_animals/plant3.png',
                        imgFour:
                          'asset:/problems/plants_animals/catterpiller.jpg',
                        imgFive: 'asset:/problems/plants_animals/bee.jpg',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        postion: [1, 2, 3],
                      },

                      {
                        id: '4',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant1.png',
                        imgTwo: 'asset:/problems/plants_animals/plant2.png',
                        imgThree: 'asset:/problems/plants_animals/plant3.png',
                        imgFour:
                          'asset:/problems/plants_animals/catterpiller.jpg',
                        imgFive: 'asset:/problems/plants_animals/plant6.png',
                        imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

                        postion: [1, 2, 3, 5],
                      },

                      {
                        id: '5',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/dog.jpg',
                        imgThree: 'asset:/problems/plants_animals/fish2.png',
                        imgFour: 'asset:/problems/plants_animals/plant3.png',
                        imgFive: 'asset:/problems/plants_animals/duck.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant5.png',

                        postion: [4, 6],
                      },

                      {
                        id: '6',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/deer.jpg',
                        imgTwo: 'asset:/problems/plants_animals/plant8.png',
                        imgThree: 'asset:/problems/plants_animals/fish2.png',
                        imgFour: 'asset:/problems/plants_animals/plant3.png',
                        imgFive: 'asset:/problems/plants_animals/duck.jpg',
                        imgSix: 'asset:/problems/plants_animals/plant5.png',

                        postion: [2, 4, 6],
                      },

                      {
                        id: '7',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant12.png',
                        imgTwo: 'asset:/problems/plants_animals/insect2.png',
                        imgThree: 'asset:/problems/plants_animals/fish3.png',
                        imgFour: 'asset:/problems/plants_animals/insect4.png',
                        imgFive: 'asset:/problems/plants_animals/fish2.png',
                        imgSix: 'asset:/problems/plants_animals/plant9.png',

                        postion: [1, 6],
                      },

                      {
                        id: '8',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/insect2.png',
                        imgThree: 'asset:/problems/plants_animals/fish3.png',
                        imgFour: 'asset:/problems/plants_animals/fox.jpg',
                        imgFive: 'asset:/problems/plants_animals/fish2.png',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        postion: [1],
                      },

                      {
                        id: '9',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/plant11.png',
                        imgThree: 'asset:/problems/plants_animals/plant12.png',
                        imgFour: 'asset:/problems/plants_animals/plant13.png',
                        imgFive: 'asset:/problems/plants_animals/plant15.png',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        postion: [6],
                      },

                      {
                        id: '10',
                        title: 'Select all the Plants ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/plants_animals/plant10.png',
                        imgTwo: 'asset:/problems/plants_animals/plant11.png',
                        imgThree: 'asset:/problems/plants_animals/cow.jpg',
                        imgFour: 'asset:/problems/plants_animals/plant13.png',
                        imgFive: 'asset:/problems/plants_animals/dolphen.jpg',
                        imgSix: 'asset:/problems/plants_animals/eagle.png',

                        postion: [3, 5, 6],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          // Energy

          {
            categoryName: 'Energy',
            categoryId: '1221',
            topic: [
              {
                key: '1',
                topicName: 'People or Fuel',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '2',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/trolly.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/motorbike.png',
                          'asset:/problems/power/cycle.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/pushingcar.png',
                          'asset:/problems/power/bus.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/plane.png',
                          'asset:/problems/power/jym.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/plane.png',
                          'asset:/problems/power/pushingcarr.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/manrope.png',
                          'asset:/problems/power/train.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/trolly.png',
                          'asset:/problems/power/train.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/manrope.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/train.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/jym.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/plane.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/trolly.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '7',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/tractor.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/boat.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/car.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/cycle.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },

                  // level 3

                  {
                    levelName: 3,
                    numberOfQuestion: 10,
                    questions: [
                      {
                        id: '1',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [1, 4],
                      },

                      {
                        id: '2',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [2, 3],
                      },

                      {
                        id: '3',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2, 3],
                      },

                      {
                        id: '4',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [4],
                      },

                      {
                        id: '5',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/boat.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2],
                      },

                      {
                        id: '6',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/cycle.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/running.png',

                        position: [1, 4],
                      },

                      {
                        id: '7',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/motorbike.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [2, 3],
                      },

                      {
                        id: '8',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/manrope.png',
                        imgTwo: 'asset:/problems/power/jym.png',
                        imgThree: 'asset:/problems/power/pushingcarr.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2, 3],
                      },

                      {
                        id: '9',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/plane.png',

                        position: [4],
                      },

                      {
                        id: '10',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/running.png',
                        imgTwo: 'asset:/problems/power/jym.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2],
                      },
                    ],
                  },
                ],
              },

              // Sunshine and melting

              {
                key: '2',
                topicName: 'Sunshine and',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/butter.png',
                          'asset:/problems/power/melt/rock.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '2',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/ice.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/soap.png',
                          'asset:/problems/power/melt/wood.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/marble.png',
                          'asset:/problems/power/melt/chocalate.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/icecream.png',
                          'asset:/problems/power/melt/marble.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/quarter.png',
                          'asset:/problems/power/melt/caryons.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/chocalate.png',
                          'asset:/problems/power/melt/wood.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/butter.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/soap.png',
                          'asset:/problems/power/melt/rock.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/icecream.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      ////////////////////////////////////////// // MAth  KG ///////////////////////////////////////////////////////////
      {
        subjectName: 'math',

        categories: [
          {
            // count objects
            categoryName: 'Count Objects',
            categoryId: '144',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple1.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    options: ['1', '4', '2', '3'],

                    answer: '1',
                  },

                  {
                    id: '2',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple2.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    options: ['1', '4', '2', '3'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '2',
                  },

                  {
                    id: '3',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple3.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['1', '4', '2', '3'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '3',
                  },

                  {
                    id: '4',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple04.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    options: ['1', '4', '2', '3'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '4',
                  },

                  {
                    id: '5',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple5.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    options: ['5', '4', '2', '3'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '5',
                  },

                  {
                    id: '6',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple6.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    options: ['5', '4', '2', '6'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '6',
                  },

                  {
                    id: '7',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/appple7.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    options: ['5', '4', '7', '6'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '7',
                  },

                  {
                    id: '8',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple8.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    options: ['6', '8', '7', '3'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '8',
                  },

                  {
                    id: '9',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple9.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '2', '8'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '9',
                  },

                  {
                    id: '10',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple10.png'],
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple0011.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '10', '11', '12'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '11',
                  },

                  {
                    id: '2',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple12.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['10', '11', '12', '13'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '12',
                  },

                  {
                    id: '3',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple6.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '6', '8'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '6',
                  },

                  {
                    id: '4',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple15.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '12', '15'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '15',
                  },

                  {
                    id: '5',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple19.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '12', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '19',
                  },

                  {
                    id: '6',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple16.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['16', '17', '12', '15'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '16',
                  },

                  {
                    id: '7',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/appple7.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '2', '8'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '7',
                  },

                  {
                    id: '8',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple9.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '2', '8'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '9',
                  },

                  {
                    id: '9',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple20.png'],
                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '20', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '20',
                  },

                  {
                    id: '10',
                    title: 'Count the ',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple18.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '12', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '18',
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple22.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['22', '23', '25', '24'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '22',
                  },

                  {
                    id: '2',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple16.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '16', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '16',
                  },

                  {
                    id: '3',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple26.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['26', '27', '25', '28'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '26',
                  },

                  {
                    id: '4',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple30.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['29', '27', '28', '30'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '30',
                  },

                  {
                    id: '5',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple5.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '2', '5'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '5',
                  },

                  {
                    id: '6',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple30.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['26', '27', '28', '30'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '30',
                  },

                  {
                    id: '7',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple17.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '20', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '17',
                  },

                  {
                    id: '8',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple9.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['9', '7', '2', '8'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '9',
                  },

                  {
                    id: '9',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple24.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['20', '24', '22', '23'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '24',
                  },

                  {
                    id: '10',
                    title: 'Count the apples',
                    questionType: 'count-click',
                    images: ['asset:/problems/object/apple20.png'],

                    background: 'asset:/problems/ocean/fish9.gif',
                    backgroundType: 'image',

                    options: ['19', '17', '20', '18'],
                    optionsBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: '20',
                  },
                ],
              },
            ],
          },

          {
            // correct symbols
            categoryName: 'Correct Symbols',
            categoryId: '550055',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [1, 2],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [2, 5],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [4, 9],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 9],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 7],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [3, 3],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [5, 1],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 3],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [7, 9],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [10, 10],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [11, 2],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [12, 5],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [14, 9],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 18],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 17],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [13, 13],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [15, 10],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [19, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [17, 19],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [7, 10],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [11, 22],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [12, 25],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [29, 14],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [29, 18],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [19, 17],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [23, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [15, 20],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [25, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [27, 19],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [23, 10],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },
            ],
          },

          {
            // Skip Counts
            categoryName: 'Skip Counts',
            categoryId: '8888',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['2', '8'],
                    answer: ['4', '6', '10', '12'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['2', '10'], // value ( 1 and 5 )
                    answer: ['4', '6', '8', '12'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '6',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '22'], // value ( 2 and 5 )
                    answer: ['14', '18', '20', '24'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['18', '20'],
                    answer: ['12', '14', '16', '22'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '8',

                    initial: ['32', '38'],
                    answer: ['34', '36', '40', '42'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['12', '18'],
                    answer: ['14', '16', '18', '20'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '22'], // value ( 2 and 5 )
                    answer: ['14', '18', '20', '24'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['18', '20'],
                    answer: ['12', '14', '16', '18'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '20'], // value ( 1 and 3 )
                    answer: ['18', '22', '24', '26'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['32', '38'],
                    answer: ['34', '36', '40', '42'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['38', '40'],
                    answer: ['32', '34', '36', '42'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '40'], // value ( 1 and 3 )
                    answer: ['34', '32', '38', '42'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },

          {
            // Count backwards
            categoryName: 'Count backwards',
            categoryId: '001',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['5', '2'],
                    answer: ['4', '3', '1', '9'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['12', '9'], // value ( 2 and 5 )
                    answer: ['13', '11', '10', '9'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['2', '1'],
                    answer: ['5', '4', '3', '6'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['16', '14'], // value ( 1 and 3 )
                    answer: ['15', '13', '12', '11'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['11', '7'], // value ( 1 and 5 )
                    answer: ['10', '9', '8', '6'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['15', '12'],
                    answer: ['14', '13', '11', '19'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['18', '15'], // value ( 2 and 5 )
                    answer: ['19', '17', '16', '9'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['12', '11'],
                    answer: ['15', '14', '13', '16'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['20', '18'], // value ( 1 and 3 )
                    answer: ['19', '17', '16', '15'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['19', '15'], // value ( 1 and 5 )
                    answer: ['18', '17', '16', '14'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['15', '12'],
                    answer: ['14', '13', '11', '19'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['22', '19'], // value ( 2 and 5 )
                    answer: ['23', '21', '20', '22'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['27', '26'],
                    answer: ['30', '29', '28', '25'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['26', '24'], // value ( 1 and 3 )
                    answer: ['25', '23', '22', '21'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['21', '17'], // value ( 1 and 5 )
                    answer: ['20', '19', '18', '16'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['5', '2'],
                    answer: ['4', '3', '1', '6'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['28', '25'], // value ( 2 and 5 )
                    answer: ['29', '27', '26', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['22', '21'],
                    answer: ['25', '24', '23', '26'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['16', '14'], // value ( 1 and 3 )
                    answer: ['15', '13', '12', '11'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['9', '5'], // value ( 1 and 5 )
                    answer: ['8', '7', '6', '4'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['25', '22'],
                    answer: ['24', '23', '21', '29'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['32', '39'], // value ( 2 and 5 )
                    answer: ['33', '31', '30', '34'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['37', '36'],
                    answer: ['40', '39', '38', '35'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['36', '34'], // value ( 1 and 3 )
                    answer: ['35', '33', '32', '31'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['31', '37'], // value ( 1 and 5 )
                    answer: ['30', '29', '28', '28'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['35', '32'],
                    answer: ['34', '33', '31', '36'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['28', '25'], // value ( 2 and 5 )
                    answer: ['29', '27', '26', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['2', '1'],
                    answer: ['5', '4', '3', '6'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['26', '24'], // value ( 1 and 3 )
                    answer: ['25', '23', '22', '21'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['19', '15'], // value ( 1 and 5 )
                    answer: ['18', '17', '16', '14'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },

          // More less
          {
            categoryName: 'More or Less',
            categoryId: '17777',
            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: 'asset:/backgrounds/bg_ordinal.png',
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple1.png',
                      'asset:/problems/object/apple2.png',
                    ],

                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple3.png',
                      'asset:/problems/object/apple2.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/appple7.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple3.png',
                      'asset:/problems/object/apple5.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/appple7.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple6.png',
                      'asset:/problems/object/apple10.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple2.png',
                      'asset:/problems/object/apple8.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple5.png',
                      'asset:/problems/object/apple10.png',
                    ],
                    answer: 2,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple11.png',
                      'asset:/problems/object/apple12.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple13.png',
                      'asset:/problems/object/apple12.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple17.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple13.png',
                      'asset:/problems/object/apple15.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple17.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple16.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple12.png',
                      'asset:/problems/object/apple18.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple15.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 2,
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple22.png',
                      'asset:/problems/object/apple24.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple23.png',
                      'asset:/problems/object/apple21.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple24.png',
                      'asset:/problems/object/apple27.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple23.png',
                      'asset:/problems/object/apple25.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple27.png',
                      'asset:/problems/object/apple29.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple26.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple12.png',
                      'asset:/problems/object/apple18.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple29.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple15.png',
                      'asset:/problems/object/apple30.png',
                    ],
                    answer: 2,
                  },
                ],
              },
            ],
          },
          // Even Odd
          {
            categoryName: 'Even Odd',
            categoryId: '55',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple1.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple2.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple3.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple04.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple8.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple10.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple9.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple6.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple11.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple12.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple13.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple04.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple18.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple19.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple21.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple26.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple23.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple29.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple18.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple27.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple19.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },
            ],
          },
          // Missing Numbers
          {
            categoryName: 'Missing Numbers',
            categoryId: '5055',
            level: [
              // level 1

              {
                key: '1',
                levelName: 1,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['1', '4'],
                    answer: ['2', '3', '5', '6'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['6', '9'], // value ( 2 and 5 )
                    answer: ['5', '7', '8', '10'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['7', '8'],
                    answer: ['4', '5', '6', '9'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '5'], // value ( 1 and 2 )
                    answer: ['4', '6', '7', '8'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['5', '9'], // value ( 1 and 5 )
                    answer: ['6', '7', '8', '10'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['3', '6'],
                    answer: ['4', '5', '7', '8'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '6'], // value ( 2 and 5 )
                    answer: ['2', '4', '5', '7'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['9', '10'],
                    answer: ['6', '7', '8', '11'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '5'], // value ( 1 and 2 )
                    answer: ['4', '6', '7', '8'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['4', '8'], // value ( 1 and 5 )
                    answer: ['5', '6', '7', '9'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2

              {
                key: '1',
                levelName: 2,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['11', '14'],
                    answer: ['12', '13', '15', '16'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['16', '19'], // value ( 2 and 5 )
                    answer: ['15', '17', '18', '20'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['17', '18'],
                    answer: ['14', '15', '16', '19'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '15'], // value ( 1 and 2 )
                    answer: ['14', '16', '17', '18'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['15', '19'], // value ( 1 and 5 )
                    answer: ['16', '17', '18', '20'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['9', '13'],
                    answer: ['10', '11', '12', ' 14'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '16'], // value ( 2 and 5 )
                    answer: ['12', '14', '15', '17'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['19', '20'],
                    answer: ['16', '17', '18', '21'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '15'], // value ( 1 and 2 )
                    answer: ['14', '16', '17', '18'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['14', '18'], // value ( 1 and 5 )
                    answer: ['15', '16', '17', '19'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3

              {
                key: '1',
                levelName: 3,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['21', '24'],
                    answer: ['22', '23', '25', '26'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'MissFill the missing numbersing',
                    initial: ['26', '29'], // value ( 2 and 5 )
                    answer: ['25', '27', '28', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['27', '28'],
                    answer: ['24', '25', '26', '29'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['23', '25'], // value ( 1 and 2 )
                    answer: ['24', '26', '27', '28'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['15', '19'], // value ( 1 and 5 )
                    answer: ['16', '17', '18', '20'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['3', '6'],
                    answer: ['4', '5', '6', '7'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '16'], // value ( 2 and 5 )
                    answer: ['12', '14', '15', '17'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['29', '20'],
                    answer: ['26', '27', '28', '29'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['23', '25'], // value ( 1 and 2 )
                    answer: ['24', '26', '27', '28'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['24', '28'], // value ( 1 and 5 )
                    answer: ['25', '26', '27', '29'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },
          // least_Greatest
          {
            categoryName: 'Least to Greatest',
            categoryId: '484890',
            level: [
              // level 1

              {
                key: '1',
                levelName: 1,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [5, 3, 2, 1, 4, 6],
                    answer: [1, 2, 3, 4, 5, 6],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [8, 3, 5, 4, 7, 6],
                    answer: [3, 4, 5, 6, 7, 8],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [9, 10, 6, 8, 7, 5],
                    answer: [5, 6, 7, 8, 9, 10],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [15, 11, 16, 12, 14, 13],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [12, 7, 11, 8, 10, 9],
                    answer: [7, 8, 9, 10, 11, 12],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [15, 13, 12, 11, 14, 16],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [18, 13, 15, 14, 17, 16],
                    answer: [13, 14, 15, 16, 17, 18],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [19, 20, 16, 18, 17, 15],
                    answer: [15, 16, 17, 18, 19, 20],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [25, 21, 26, 22, 24, 23],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },

              // level 2

              {
                key: '1',
                levelName: 2,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [15, 13, 12, 11, 14, 16],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [18, 13, 15, 14, 17, 16],
                    answer: [13, 14, 15, 16, 17, 18],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [19, 20, 16, 18, 17, 15],
                    answer: [15, 16, 17, 18, 19, 20],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [25, 21, 26, 22, 24, 23],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [25, 23, 22, 21, 24, 26],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [28, 23, 25, 24, 27, 26],
                    answer: [23, 24, 25, 26, 27, 28],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [29, 30, 26, 28, 27, 25],
                    answer: [25, 26, 27, 28, 29, 30],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [35, 31, 36, 32, 34, 33],
                    answer: [31, 32, 23, 34, 35, 36],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [32, 27, 31, 28, 30, 29],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },

              // level 3

              {
                key: '1',
                levelName: 3,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [25, 23, 22, 21, 24, 26],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [28, 23, 25, 24, 27, 26],
                    answer: [23, 24, 25, 26, 27, 28],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [29, 30, 26, 28, 27, 25],
                    answer: [25, 26, 27, 28, 29, 30],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [35, 31, 36, 32, 34, 33],
                    answer: [31, 32, 33, 34, 35, 36],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [42, 37, 41, 38, 40, 39],
                    answer: [37, 38, 39, 40, 41, 42],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [35, 33, 32, 31, 34, 36],
                    answer: [31, 32, 33, 34, 35, 36],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [38, 33, 35, 34, 37, 36],
                    answer: [33, 34, 35, 36, 37, 38],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [39, 40, 36, 38, 37, 35],
                    answer: [35, 36, 37, 38, 39, 40],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [5, 1, 6, 2, 4, 3],
                    answer: [1, 3, 2, 3, 5, 6],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },
            ],
          },
          // Addition
          {
            categoryName: 'Addition',
            categoryId: '92478',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'addsubtract-click',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',
                    backgroundType: 'image',
                    initial: [2, 2],
                    options: [5, 3, 4],
                    answer: 4,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [2, 7],
                    options: [6, 9, 2],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 2],
                    options: [3, 2, 7],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [1, 5],
                    options: [6, 8, 7],
                    answer: 0,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [4, 5],
                    options: [6, 9, 8],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [2, 3],
                    options: [8, 3, 5],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 5],
                    options: [3, 9, 10],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [3, 2],
                    options: [2, 5, 10],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [1, 1],
                    options: [3, 6, 2],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 1],
                    options: [4, 6, 8],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [12, 2],
                    options: [15, 13, 14],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [12, 7],
                    options: [16, 19, 12],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 12],
                    options: [13, 12, 17],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [11, 5],
                    options: [16, 18, 17],
                    answer: 0,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [4, 15],
                    options: [16, 19, 18],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 10],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [2, 13],
                    options: [18, 13, 15],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 15],
                    options: [13, 19, 20],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [13, 2],
                    options: [12, 15, 10],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [1, 11],
                    options: [13, 16, 12],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 11],
                    options: [14, 16, 18],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [12, 12],
                    options: [25, 23, 24],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [2, 27],
                    options: [26, 29, 22],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [15, 12],
                    options: [23, 22, 27],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [11, 15],
                    options: [26, 28, 27],
                    answer: 0,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [14, 15],
                    options: [26, 29, 28],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [16, 13],
                    options: [23, 29, 30],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [12, 13],
                    options: [28, 23, 25],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [15, 15],
                    options: [23, 29, 30],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [13, 12],
                    options: [22, 25, 20],
                    answer: 1,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [11, 11],
                    options: [23, 26, 22],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [15, 11],
                    options: [24, 26, 28],
                    answer: 2,
                    operator: '+',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },
            ],
          },
          // Before After
          {
            categoryName: 'Count Objects',
            categoryId: '3321',
            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'What Comes Before?',
                    questionType: 'before-word',
                    layout: 'before',
                    initial: [3, 4],
                    options: [4, 2, 5, 6],
                    answer: 2
                  },
                  {
                    id: '1',
                    title: 'What Comes Between?',
                    questionType: 'before-word',
                    layout: 'between',
                    initial: [3, 5],
                    options: [4, 1, 5, 6],
                    answer: 4
                  },
                  {
                    id: '1',
                    title: 'What Comes After?',
                    questionType: 'before-word',
                    layout: 'after',
                    initial: [3, 4],
                    options: [7, 1, 5, 6],
                    answer: 5
                  },
                ],
              },
            ],
          },

          // subtraction
          {
            categoryName: 'subtraction',
            categoryId: '1110011',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 2],
                    options: [5, 3, 4],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [7, 2],
                    options: [5, 9, 2],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [5, 4],
                    options: [2, 1, 3],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [10, 5],
                    options: [6, 5, 8],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [8, 2],
                    options: [5, 3, 6],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [9, 2],
                    options: [7, 9, 2],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [10, 4],
                    options: [5, 6, 7],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [8, 5],
                    options: [6, 5, 3],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [15, 2],
                    options: [15, 13, 14],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [17, 2],
                    options: [15, 19, 12],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [15, 4],
                    options: [12, 11, 13],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [20, 5],
                    options: [16, 15, 18],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 20],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [18, 2],
                    options: [15, 13, 16],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [19, 2],
                    options: [17, 19, 12],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [20, 4],
                    options: [15, 16, 17],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [18, 5],
                    options: [16, 15, 13],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 20],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [25, 2],
                    options: [25, 23, 24],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [27, 2],
                    options: [25, 29, 22],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [25, 14],
                    options: [7, 2, 11],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [20, 15],
                    options: [6, 5, 8],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [26, 23],
                    options: [3, 2, 4],
                    answer: 0,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [28, 19],
                    options: [21, 21, 11],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [29, 20],
                    options: [7, 9, 2],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [20, 14],
                    options: [5, 6, 7],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [28, 15],
                    options: [26, 25, 13],
                    answer: 2,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: 'asset:/backgrounds/bg_ordinal.png',

                    backgroundType: 'image',
                    initial: [26, 13],
                    options: [23, 13, 10],
                    answer: 1,
                    operator: '-',
                    backgroundButtons: ['red', 'green', 'blue'],
                  },
                ],
              },
            ],
          },

          // Bar chart
          {
            categoryName: 'Barchart',
            categoryId: '11100111',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like footbal',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['25', '30', '35'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like globe',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['25', '35', '45'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like rocket',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['35', '45', '50'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like car',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['35', '45', '50'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like ball',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '6'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like bat',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '5'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    title: 'How many kids like pencil',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '10', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    title: 'How many kids like clock',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '8', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like plane',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '6'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like car',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '5'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like bus',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['9', '10', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '3',
                    title: 'How many kids like bike',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '8', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },
            ],
          },

          // True False
          {
            categoryName: 'True and False',
            categoryId: '09000',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['12  +  12  =  24'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['6  -  3  =  3'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['6  +  3  =  9'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['7  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['12  +  6  =  24'],

                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  6  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  3  =  13'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  +  3  =  19'],

                    answer: 0,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  5  =  15'],

                    answer: 1,
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  8  =  16'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['10  +  6  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  3  =  13'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  3  =  11'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  6  =  14'],

                    answer: 0,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  13  =  3'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  +  13  =  19'],

                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  15  =  15'],

                    answer: 1,
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  8  =  26'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['10  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  -  13  =  13'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  13  =  31'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['27  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  6  =  24'],

                    answer: 0,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['23  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  -  23  =  3'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  +  13  =  19'],

                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['27  -  15  =  15'],

                    answer: 1,
                  },
                ],
              },
            ],
          },

          // Word Problems
          {
            categoryName: 'Word Problems',
            categoryId: '0100',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [


                  {
                    id: '1',
                    questionType: 'word-problems-click',
                    title:
                      'Json purchased 7 KG of sugar and 4 KG of rice.What is the total weight Json carried?',
                    initial: [1, 2],
                    options: [
                      '1',
                      '2',
                      '7',
                      '5',
                      '5',
                      '4',
                      '7',
                      '8',
                      '4',
                      '9',
                      '11',
                      '6',
                    ],
                    answer: [2, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      'Three red marbles and three green marbles are on the table.How many marbles are on the tables ?',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'Three red marbles and three green marbles are on the table.How many marbles are on the tables ?',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'There are four small candies and five big candies in the box.How many candies are there altogether?',
                    initial: [1, 2],
                    options: ['5', '4', '9'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'Ava has 5 flowers and david give her 6 more. How many flowers does she have now ?',
                    initial: [1, 2],
                    options: ['5', '6', '11'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'One farmer has 10 lambs and other have 13. How many lambs do the two farmers have together?',
                    initial: [1, 2],
                    options: ['13', '10', '23'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      '6 people are on the bus. 4 more people get on the bus. How many people are on the bsu now?',
                    initial: [1, 2],
                    options: ['6', '4', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      'Sam has 5 blocks. He found 4 more.How many blocks does he have now?',
                    initial: [1, 2],
                    options: ['4', '5', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      'There are 12 aeroplanes in an airport. 6 more land.How many aeroplanes in total?',
                    initial: [1, 2],
                    options: ['12', '6', '18'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'There are 10 fishes , 7 more come along. How many fishes altogether?',
                    initial: [1, 2],
                    options: ['10', '7', '17'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'word-problems',
                    title:
                      'I have 2 books and my friend has 4 books. How many books are there total? ',
                    initial: [1, 2],
                    options: ['2', '4', '6'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      'Marry has 3 bananas anf 4 cherries. How many fruits does she have in all?',
                    initial: [1, 2],
                    options: ['3', '4', '7'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'joe has 5 ballons and barry have 6 . How many ballon they have in all?                    ',
                    initial: [1, 2],
                    options: ['5', '6', '11'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'There are 7 men 7 woman in the bus. How many person are there in the bus?',
                    initial: [1, 2],
                    options: ['7', '7', '14'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'There are 14 boys and 13 girls in a class. How many students are there  in a class?',
                    initial: [1, 2],
                    options: ['14', '13', '27'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'Sam has 6 blue  and 7 red balls. How many balls does he have ?',
                    initial: [1, 2],
                    options: ['6', '7', '13'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      '6 people are on the bus. 4 more people get on the bus. How many people are on the bus now?',
                    initial: [1, 2],
                    options: ['6', '4', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      'Sally has 12 pens. and frazer has 8. How many pens are there in total ?',
                    initial: [1, 2],
                    options: ['12', '8', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      'Lilly bought 8 pencils and pam bought 7. How many penils they have together ?',
                    initial: [1, 2],
                    options: ['8', '7', '15'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'In a jungle there are 15 elephants and 10 deers. How many animals are there in jungle?',
                    initial: [1, 2],
                    options: ['15', '10', '25'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'word-problems',
                    title:
                      'Tom has 3 yellow pencils and 3 red pencils. How many pencils does he have? ',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      ' There are 4 roses and 3 sunflower in my garden. How many flowers are there in all?',
                    initial: [1, 2],
                    options: ['3', '4', '7'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'There are 5 oranges slices in plate.john adds 4 more. How many orange slices are there in the palte now ?                  ',
                    initial: [1, 2],
                    options: ['5', '4', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'Tina has 5 candies / Rosy has 4 candies. how many candies they have all?',
                    initial: [1, 2],
                    options: ['4', '5', '9'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'There are 6 birds sitting on a tree. 3 more come there. How many birds are there in all?',
                    initial: [1, 2],
                    options: ['6', '3', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'john had 7 coins. His father gave him 3 more. how many coins does john has now ?',
                    initial: [1, 2],
                    options: ['7', '3', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      'Lilly bought 8 pencils and pam bought 7. How many penils they have togethere?',
                    initial: [1, 2],
                    options: ['7', '8', '15'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      ' There are 8 cats on the and 7 on the ground. How many cats are there ?',
                    initial: [1, 2],
                    options: ['8', '7', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      ' Rahul took 15 steps. then he took 5 more steps . How many steps he walked in all ?',
                    initial: [1, 2],
                    options: ['15', '5', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'Sara ate slice of 4 pizza  Jack ate 6. how many slices of pizza did they eat together',
                    initial: [1, 2],
                    options: ['4', '6', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },
            ],
          },

          // Time
          {
            categoryName: 'Time',
            categoryId: '010010111',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/4.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '03:00', '04:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/6.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['06:00', '07:00', '08:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/9.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['11:00', '10:00', '09:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/12.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['12:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/3.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['03:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '7',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/8.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['09:00', '08:00', '10:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '8',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/9.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['09:00', '010:00', '08:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '9',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '06:00', '07:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '10',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/11.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['11:00', '12:00', '01:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Which clock shows two oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/1.png', 'asset:/time1/2.png'],
                    answer: 2,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Which clock shows three oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/3.png', 'asset:/time1/2.png'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Which clock shows four oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/3.png', 'asset:/time/4.png'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Which clock shows seven oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/7.png', 'asset:/time1/9.png'],
                    answer: 1,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Which clock shows ten oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/7.png', 'asset:/time/10.png'],
                    answer: 2,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Which clock shows one oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/1.png', 'asset:/time/12.png'],
                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Which clock shows six oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/12.png', 'asset:/time1/6.png'],
                    answer: 2,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Which clock shows nine oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/9.png', 'asset:/time1/6.png'],
                    answer: 1,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Which clock shows eleven oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/11.png', 'asset:/time1/5.png'],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Which clock shows ten oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/12.png', 'asset:/time/10.png'],
                    answer: 2,
                  },
                ],
              },
            ],
          },

          // Ordinal numbers
          {
            categoryName: 'Ordinal',
            categoryId: '33001100',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'At which position A.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['2nd', '3rd', '4th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'At which position B.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['6th', '7th', '8th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'At which position C.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['1st', '2nd', '3rd'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'At which position D.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['1st', '2nd', '3rd'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '5',
                    title: 'At which position E.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '6',
                    title: 'At which position F.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'At which position H.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '6th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '6',
                    title: 'At which position G.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },
            ],
          },

          // Comparison
          {
            categoryName: 'Comparison',
            categoryId: '0210',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/star.png', 'asset:/star.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/4.png', 'asset:/8.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/2.png', 'asset:/2.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Slect the small object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/3.png', 'asset:/3.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/4.png', 'asset:/4.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Slect the small object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/5.png', 'asset:/5.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 2,
                  },
                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/6.png', 'asset:/6.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/7.png', 'asset:/7.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },
                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/8.png', 'asset:/8.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/9.png', 'asset:/9.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  ////////////////////////////////grade 1  Secience levels //////////////////////////////////////////////

  {
    grade: 'one',

    subjects: [
      {
        subjectName: 'science',

        categories: [
          ///////////////////////////  Animals  Questions grade 1   ///////////////////////

          {
            categoryName: 'Animals',
            categoryId: '0001',
            topic: [
              // what animals eat 1

              {
                key: '1',
                topicName: 'What Animals eat',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    // questions: [
                    //   {
                    //     id: '1',

                    //     title: 'What does cow eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/plants_animals/cow.jpg',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     imgOne:
                    //       'https://images.freeimages.com/images/small-previews/33d/kusbasi-1327458.jpg',
                    //     imgTwo:
                    //       'https://freepngimg.com/thumb/field/28332-4-field-file.png',
                    //     imgThree:
                    //       'https://images.immediate.co.uk/production/volatile/sites/4/2021/05/GettyImages-186823289-6e83876.jpg?quality=90&resize=768,574',
                    //     answer: 2,
                    //   },

                    //   {
                    //     id: '2',

                    //     title: 'What does parrot eat',
                    //     questionType: 'single-select',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     object:
                    //       'https://res.cloudinary.com/dk-find-out/image/upload/q_80,w_960,f_auto/DCTM_Penguin_UK_DK_AL526630_wkmzns.jpg',
                    //     imgOne:
                    //       'https://images.freeimages.com/images/small-previews/33d/kusbasi-1327458.jpg',
                    //     imgTwo:
                    //       'https://freepngimg.com/thumb/field/28332-4-field-file.png',
                    //     imgThree:
                    //       'https://images.immediate.co.uk/production/volatile/sites/4/2021/05/GettyImages-186823289-6e83876.jpg?quality=90&resize=768,574',
                    //     answer: 3,
                    //   },

                    //   {
                    //     id: '3',

                    //     title: 'What does lion eat',
                    //     questionType: 'single-select',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     object:
                    //       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrRtX63n-cEtiv_DAuoTjXUjx4sqWFVjAz0A&usqp=CAU',
                    //     imgOne:
                    //       'https://images.freeimages.com/images/small-previews/33d/kusbasi-1327458.jpg',
                    //     imgTwo:
                    //       'https://freepngimg.com/thumb/field/28332-4-field-file.png',
                    //     imgThree:
                    //       'https://images.immediate.co.uk/production/volatile/sites/4/2021/05/GettyImages-186823289-6e83876.jpg?quality=90&resize=768,574',
                    //     answer: 1,
                    //   },

                    //   {
                    //     id: '4',

                    //     title: 'What does bear eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/bear.png',
                    //     imgOne: 'asset:/problems/eat/honey.png',
                    //     imgTwo: 'asset:/problems/eat/tree.png',
                    //     imgThree: 'asset:/problems/eat/dog.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 1,
                    //   },

                    //   {
                    //     id: '5',

                    //     title: 'What does penguin eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/penguin.png',
                    //     imgOne: 'asset:/problems/eat/honey.png',
                    //     imgTwo: 'asset:/problems/eat/tree.png',
                    //     imgThree: 'asset:/problems/eat/fish.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 3,
                    //   },

                    //   {
                    //     id: '6',

                    //     title: 'What does frog eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/frog.png',
                    //     imgOne: 'asset:/problems/eat/honey.png',
                    //     imgTwo: 'asset:/problems/eat/pug.png',
                    //     imgThree: 'asset:/problems/eat/fish.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 2,
                    //   },

                    //   {
                    //     id: '7',

                    //     title: 'What does elephant eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/elephant.png',
                    //     imgOne: 'asset:/problems/eat/sugar.png',
                    //     imgTwo: 'asset:/problems/eat/pug.png',
                    //     imgThree: 'asset:/problems/eat/fish.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 1,
                    //   },

                    //   {
                    //     id: '8',

                    //     title: 'What does cat eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/cat.png',
                    //     imgOne: 'asset:/problems/eat/sugar.png',
                    //     imgTwo: 'asset:/problems/eat/meat.png',
                    //     imgThree: 'asset:/problems/eat/tree.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 2,
                    //   },

                    //   {
                    //     id: '9',

                    //     title: 'What does jiraffe eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/jiraffe.png',
                    //     imgOne: 'asset:/problems/eat/sugar.png',
                    //     imgTwo: 'asset:/problems/eat/pug.png',
                    //     imgThree: 'asset:/problems/eat/tree.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 3,
                    //   },

                    //   {
                    //     id: '10',

                    //     title: 'What does dog eat',
                    //     questionType: 'single-select',
                    //     object: 'asset:/problems/eat/dog.png',
                    //     imgOne: 'asset:/problems/eat/sugar.png',
                    //     imgTwo: 'asset:/problems/eat/meat.png',
                    //     imgThree: 'asset:/problems/eat/tree.png',
                    //     background: 'asset:/problems/ocean/fish9.gif',
                    //     backgroundType: 'image',
                    //     answer: 2,
                    //   },
                    // ],
                  },

                  // level 2

                  {
                    key: '2',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'What does cow eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/cow.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['grass', 'meat', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'What does leopard eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/leopard.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['grass', 'meat', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'What does goat eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/goat.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'seed'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title: 'What does rooster eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/rooster.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'seed'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'What does ostrich eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/ostrich.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'seed'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '6',
                        title: 'What does wolf eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/wolf.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'seed'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title: 'What does goose eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/goose.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title: 'What does hare eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/hare.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '8',
                        title: 'What does vulture eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/vulture.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'What does penguin eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/penguin.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'What does sheep eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/sheep.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['meat', 'grass', 'insects'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // Animal habitats 2

              // level 1

              {
                key: '2',
                topicName: 'Animal Habitats',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',

                        title: 'Where does gorilla live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/eat/gorilla.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/jungle.png',
                        imgThree: 'asset:/problems/eat/desert.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 2,
                      },

                      {
                        id: '2',

                        title: 'Where does penguin live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/eat/penguin.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/jungle.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 3,
                      },

                      {
                        id: '3',

                        title: 'Where does fish live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/eat/fish.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/jungle.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 1,
                      },

                      {
                        id: '4',

                        title: 'Where does bear live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/eat/bear.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/jungle.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 2,
                      },

                      {
                        id: '5',

                        title: 'Where does polar bear live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/bear.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/jungle.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 3,
                      },

                      {
                        id: '6',

                        title: 'Where does camel live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/camel.png',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/desert.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 2,
                      },

                      {
                        id: '7',

                        title: 'Where does alligator live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/alligator.jpg',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/desert.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 1,
                      },

                      {
                        id: '8',

                        title: 'Where does dolphen live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/dolphen.jpg',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/desert.png',
                        imgThree: 'asset:/problems/eat/artic.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 1,
                      },

                      {
                        id: '9',

                        title: 'Where does monkey live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/monkey.jpg',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/desert.png',
                        imgThree: 'asset:/problems/eat/jungle.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 3,
                      },

                      {
                        id: '10',

                        title: 'Where does deer live?',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/deer.jpg',
                        imgOne: 'asset:/problems/eat/ocean.png',
                        imgTwo: 'asset:/problems/eat/desert.png',
                        imgThree: 'asset:/problems/eat/jungle.png',
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        answer: 3,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '2',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where does gorilla live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/ape.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'What does leopard live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/leopard.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '3',
                        title: 'where does fish live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/fish.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['ocean', 'jungle', 'forest'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'where does penguin live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/penguin.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'artic'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'where does bear live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title: 'where does polar bear live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'artic', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '6',
                        title: 'where does camel live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/camel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '7',
                        title: 'where does alligator live?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/alligator.jpg',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'where does dolphen live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/dolphen.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '9',
                        title: 'where does monkey live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/monkey.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'where does deer live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/home/deer.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },
                ],
              },

              // Animals and their offsprings  3

              {
                key: '3',
                topicName: 'Animals and their offsprings',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/panda.png',

                        imgOne: 'asset:/problems/baby/cub.png',
                        imgTwo: 'asset:/problems/baby/kitten.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 1,
                      },

                      {
                        id: '2',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/lion.png',

                        imgOne: 'asset:/problems/baby/cub.png',
                        imgTwo: 'asset:/problems/baby/kitten.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 3,
                      },

                      {
                        id: '3',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/sheep.png',

                        imgOne: 'asset:/problems/baby/cub.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 2,
                      },

                      {
                        id: '4',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/pig.png',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 1,
                      },

                      {
                        id: '5',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/cat.jpg',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/kitten.png',
                        answer: 3,
                      },

                      {
                        id: '6',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/hen.jpg',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/chick.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 2,
                      },

                      {
                        id: '7',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/cow.jpg',

                        imgOne: 'asset:/problems/baby/calf.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/cublion.png',
                        answer: 1,
                      },

                      {
                        id: '8',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/rabit.png',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/bunny.png',
                        answer: 3,
                      },

                      {
                        id: '9',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/frog.png',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/lamb.png',
                        imgThree: 'asset:/problems/baby/tadpol.png',
                        answer: 3,
                      },

                      {
                        id: '10',

                        title: 'Click on  baby animal',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/butterfly.png',

                        imgOne: 'asset:/problems/baby/piglet.png',
                        imgTwo: 'asset:/problems/baby/catterpiller.png',
                        imgThree: 'asset:/problems/baby/bunny.png',
                        answer: 22,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '2',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Click on the baby name  of chicken',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/chicken.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['chick', 'cub', 'kitten'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Click on the baby name  of lion',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/lion.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['chick', 'lioncub', 'kitten'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'Click on the baby name  of panda',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/panda.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['chick', 'cub', 'kitten'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title: 'Click on the baby name  of butterfly',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/butterfly.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['chick', 'cub', 'catterpiller'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'Click on the baby name  of cow',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/cow.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['calf', 'cub', 'catterpiller'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '6',
                        title: 'Click on the baby name  of sheep',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/sheep.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['calf', 'lamb', 'catterpiller'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '6',
                        title: 'Click on the baby name  of frog',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/frog.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['tadpol', 'bunny', 'chick'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '7',
                        title: 'Click on the baby name  of rabit',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/rabit.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['tadpol', 'bunny', 'chick'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '8',
                        title: 'Click on the baby name  of cat',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/dolphen.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['tadpol', 'bunny', 'kitten '],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '9',
                        title: 'where does monkey live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/monkey.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'where does deer live?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/home/deer.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['forest', 'desert', 'ocean'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },
                ],
              },

              // Animal adaptation 4

              {
                key: '4',
                topicName: 'Animal adaptation',
                level: [
                  // {
                  //   id: '1',
                  //   title: 'Choose the right option',
                  //   questionType: 'dragdrop-one',
                  //   images: ['asset:/problems/covering/cat.png'],
                  //   suggestionOne: 'Warm',
                  //   suggestionTwo: 'Cold',
                  //   suggestions: ['hair', 'feather', 'shell'],
                  //   suggestionBackgrounds: [
                  //     'red',
                  //     'green',
                  //     'blue',
                  //     'orange',
                  //   ],
                  //   answer: 1,
                  // },

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Jiraffe lives in the part of  ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['china', 'africa', 'japan'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '2',
                        title: 'Jiraffe eats',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['leaves', 'meat', 'rocks'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '3',
                        title: 'Jiraafe has long.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['ear', 'eye', 'neck'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'jiraffe has four ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/jiraffe.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['eyes', 'legs', 'ears'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // Animal Covering 5

              {
                key: '6',
                topicName: 'Animal Covering',
                level: [
                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/cat.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/armadio.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/cow.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/dove.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/pangolins.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/peacock.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '7',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/snail.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/sparrow.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '9',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/squerrel.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'Choose the right option',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/turtle.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['hair', 'feather', 'shell'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },

              // Legs and Wings 6

              {
                key: '5',
                topicName: 'Legs and Wings',
                level: [
                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/bear.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/8.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/eagle.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['3', '2', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/insect4.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '5', '4'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bee.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['5', '7', '2'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/spiderpic.png',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '5', '8'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '7',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/alligator.jpg',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '4', '8'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '8',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/bird1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['6', '4', '2'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '9',
                        title: 'How many legs of the object?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/plants_animals/hen.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['2', '4', '3'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'How many wings of the object?',
                        questionType: 'dragdrop-one',
                        images: [
                          'asset:/problems/plants_animals/butterfly.jpg',
                        ],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['2', '4', '3'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },
                ],
              },

              // How animals move 7

              {
                key: '8',
                topicName: 'How animals move',
                level: [
                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'How ants moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/pug.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['swim', 'crawl', 'jump'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '2',
                        title: 'How frogs moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/frog.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['swim', 'crawl', 'jump'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'How butterfly moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/butterfly.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'jump'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'How lion moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/lion.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'How catterpiller moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/baby/catterpiller.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '6',
                        title: 'How fish moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/fish.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'swim', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '7',
                        title: 'How dove moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/dove.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '8',
                        title: 'How snail moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/snail.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '9',
                        title: 'How sparrow moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/covering/sparrow.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '10',
                        title: 'How elephant moves?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/eat/elephant.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['fly', 'crawl', 'walk'],
                        background: 'asset:/problems/home/2.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },

              // Animal Homes 8

              {
                key: '9',
                topicName: 'Animal Homes',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',

                        title: 'Click on  home of a bee',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/bee.jpg',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/hive.png',
                        imgTwo: 'asset:/problems/home/kannel.png',
                        imgThree: 'asset:/problems/home/sea.png',
                        answer: 1,
                      },

                      {
                        id: '2',

                        title: 'Click on  home of a hen',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/hen.jpg',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/pen.png',
                        imgTwo: 'asset:/problems/home/coop.png',
                        imgThree: 'asset:/problems/home/treehollow.png',
                        answer: 2,
                      },

                      {
                        id: '3',

                        title: 'Click on  home of a monkey',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/monkey.jpg',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/tree.png',
                        imgTwo: 'asset:/problems/home/coop.png',
                        imgThree: 'asset:/problems/home/treehollow.png',
                        answer: 1,
                      },

                      {
                        id: '4',

                        title: 'Click on  home of a dog',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/dog.jpg',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/nest.png',
                        imgTwo: 'asset:/problems/home/kannel.png',
                        imgThree: 'asset:/problems/home/tree.png',
                        answer: 2,
                      },

                      {
                        id: '5',

                        title: 'Click on  home of a squerrel',
                        questionType: 'single-select',
                        object: 'asset:/problems/covering/squerrel.png',

                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/pen.png',
                        imgTwo: 'asset:/problems/home/sea.png',
                        imgThree: 'asset:/problems/home/treehollow.png',
                        answer: 3,
                      },

                      {
                        id: '6',

                        title: 'Click on  home of a dove',
                        questionType: 'single-select',
                        object: 'asset:/problems/covering/dove.png',

                        background: 'asset:/problems/home/1.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/nest.png',
                        imgTwo: 'asset:/problems/home/stable.png',
                        imgThree: 'asset:/problems/home/pen.png',
                        answer: 1,
                      },

                      {
                        id: '7',

                        title: 'Click on  home of a dolphen',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/dolphen.jpg',

                        background: 'asset:/problems/home/1.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/pen.png',
                        imgTwo: 'asset:/problems/home/sea.png',
                        imgThree: 'asset:/problems/home/tree.png',
                        answer: 2,
                      },

                      {
                        id: '8',

                        title: 'Click on  home of a horse',
                        questionType: 'single-select',
                        object: 'asset:/problems/home/horse.png',

                        background: 'asset:/problems/home/1.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/hive.png',
                        imgTwo: 'asset:/problems/home/sea.png',
                        imgThree: 'asset:/problems/home/stable.png',
                        answer: 3,
                      },

                      {
                        id: '9',

                        title: 'Click on  home of a frog',
                        questionType: 'single-select',
                        object: 'asset:/problems/plants_animals/frog.jpg',

                        background: 'asset:/problems/home/1.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/hive.png',
                        imgTwo: 'asset:/problems/home/sea.png',
                        imgThree: 'asset:/problems/home/stable.png',
                        answer: 2,
                      },

                      {
                        id: '10',

                        title: 'Click on  home of a sheep',
                        questionType: 'single-select',
                        object: 'asset:/problems/baby/lamb.png',

                        background: 'asset:/problems/home/1.gif',
                        backgroundType: 'image',

                        imgOne: 'asset:/problems/home/pen.png',
                        imgTwo: 'asset:/problems/home/sea.png',
                        imgThree: 'asset:/problems/home/stable.png',
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // ocean , farm, forest and desert animals  9

              {
                key: '10',
                topicName: 'ocean , farm, forest and desert animals',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      // {
                      //   id: '1',
                      //   title: 'Select  animals ',
                      //   questionType: 'multi-select',
                      //   imgOne: 'asset:/problems/ocean/sealion.png',
                      //   imgTwo: 'asset:/problems/ocean/horse.png',
                      //   imgThree: 'asset:/problems/ocean/seaturtle.png',
                      //   imgFour: 'asset:/problems/ocean/lion.png',
                      //   imgFive: 'asset:/problems/ocean/shark.png',
                      //   imgSix: 'asset:/problems/ocean/eagle.png',
                      //   background:'asset:/problems/home/3.gif',
                      //   backgroundType:'image',

                      //   postion: [1],
                      // },

                      {
                        id: '2',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/penguin.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/ostrich.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '3',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '4',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '5',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '6',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '7',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '8',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '9',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '10',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select all the forest animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1],
                      },

                      {
                        id: '2',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '3',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '4',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '5',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '6',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '7',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '8',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '9',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '10',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },
                    ],
                  },

                  // level 3

                  {
                    key: '1',
                    levelName: 3,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select all the farm animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1],
                      },

                      {
                        id: '2',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '3',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '4',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '5',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '6',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '7',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '8',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '9',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '10',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },
                    ],
                  },

                  // level 4

                  {
                    key: '1',
                    levelName: 4,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select all the desert animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1],
                      },

                      {
                        id: '2',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '3',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '4',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '5',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '6',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '7',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '8',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '9',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },

                      {
                        id: '10',
                        title: 'Select all the ocean animals ',
                        questionType: 'multi-select',
                        imgOne: 'asset:/problems/ocean/sealion.png',
                        imgTwo: 'asset:/problems/ocean/horse.png',
                        imgThree: 'asset:/problems/ocean/seaturtle.png',
                        imgFour: 'asset:/problems/ocean/lion.png',
                        imgFive: 'asset:/problems/ocean/shark.png',
                        imgSix: 'asset:/problems/ocean/eagle.png',
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',

                        position: [1, 3, 5],
                      },
                    ],
                  },
                ],
              },

              // plant eaters and meat eaters 10

              {
                key: '11',
                topicName: 'plant eaters and meat eaters',
                level: [
                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Elaborate the classification of lion ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/lion.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '2',
                        title: 'Elaborate the classification of horse ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/horse.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '3',
                        title: 'Elaborate the classification of penguin ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/penguin.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '4',
                        title: 'Elaborate the classification of monkey ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/monkey.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '5',
                        title: 'Elaborate the classification of leopard ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/leopard.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '6',
                        title: 'Elaborate the classification of shark ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/shark.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '7',
                        title: 'Elaborate the classification of ostrich ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/ostrich.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Elaborate the classification of elephant ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/elephant.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'Elaborate the classification of seaturtle ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/seaturtle.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Elaborate the classification of walrus ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/walrus.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: [
                          'herbivorous',
                          'carnivorous',
                          'omnivorous',
                        ],
                        background: 'asset:/problems/home/3.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // // types of Animals 11

              // {
              //   key: '12',
              //   topicName: 'Types of Animals ',
              //   level: [

              //     // level 1

              //     {
              //       key: '1',
              //       levelName: 1,
              //       start: 1,
              //       end: 10,
              //       i: 0,
              //       numberOfQuestion: 10,

              //       questions: [

              //         {
              //           id: '1',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/star.png',
              //             'asset:/star.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 0,
              //         },

              //         {
              //           id: '',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/star.png',
              //             'asset:/star.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 0,
              //         },

              //         {
              //           id: '3',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/2.png',
              //             'asset:/2.png',
              //           ],
              //           width: ['50%', '100%'],
              //           height: ['50%', '100%'],
              //           answer: 2,
              //         },

              //         {
              //           id: '4',
              //           questionType: 'single-select-two',
              //           title: 'Slect the small object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/3.png',
              //             'asset:/3.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 2,
              //         },

              //         {
              //           id: '5',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/4.png',
              //             'asset:/4.png',
              //           ],
              //           width: ['50%', '100%'],
              //           height: ['50%', '100%'],
              //           answer: 1,
              //         },
              //         {
              //           id: '6',
              //           questionType: 'single-select-two',
              //           title: 'Slect the small object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/5.png',
              //             'asset:/5.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 2,
              //         },
              //         {
              //           id: '7',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/6.png',
              //             'asset:/6.png',
              //           ],
              //           width: ['50%', '100%'],
              //           height: ['50%', '100%'],
              //           answer: 1,
              //         },
              //         {
              //           id: '8',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/7.png',
              //             'asset:/7.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 2,
              //         },
              //         {
              //           id: '9',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/8.png',
              //             'asset:/8.png',
              //           ],
              //           width: ['50%', '100%'],
              //           height: ['50%', '100%'],
              //           answer: 1,
              //         },
              //         {
              //           id: '10',
              //           questionType: 'single-select-two',
              //           title: 'Slect the big object',
              //           background: IconsAndBackground.questionBackround,
              //           backgroundType: 'image',
              //           images: [
              //             'asset:/9.png',
              //             'asset:/9.png',
              //           ],
              //           width: ['100%', '50%'],
              //           height: ['100%', '50%'],
              //           answer: 1,
              //         },

              //       ],
              //     },

              //     // level 2

              //     {
              //       key: '1',
              //       levelName: 2,
              //       start: 1,
              //       end: 10,
              //       i: 0,
              //       numberOfQuestion: 10,

              //       questions: [

              //         {
              //           id: '1',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/insect3.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '2',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/bird4.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '3',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/insect2.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '4',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/bird3.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '5',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/bee.jpg',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '6',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/owl.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '7',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/bird2.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '8',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/insect1.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '9',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/butterfly.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '10',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/insect4.png',
              //           ],

              //           suggestions: ['Bird', , 'Ant'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //       ],
              //     },

              //     // level 3
              //     {
              //       key: '1',
              //       levelName: 3,
              //       start: 1,
              //       end: 10,
              //       i: 0,
              //       numberOfQuestion: 9,

              //       questions: [

              //         {
              //           id: '1',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/plant1.png',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '2',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/monkey.jpg',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '3',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/cow.jpg',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '4',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/plant6.png',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '5',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/plant8.png',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '6',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/bee.jpg',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '7',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/elephant.jpg',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '8',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/plant11.png',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //         {
              //           id: '9',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/hen.jpg',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 2,
              //         },

              //         {
              //           id: '10',
              //           title: 'Choose the right answer',
              //           questionType: 'dragdrop-one',
              //           images: [
              //             'asset:/problems/plants_animals/plant1.png',
              //           ],

              //           suggestionOne: '1',
              //           suggestionTwo: '2',
              //           suggestions: ['Plant', , 'Animal'],
              //           suggestionBackgrounds: [
              //             'red',
              //             'green',
              //             'blue',
              //             'orange',
              //           ],
              //           answer: 0,
              //         },

              //       ],
              //     },

              //     // Leve 4
              //     {
              //       levelName: 4,
              //       numberOfQuestion: 10,
              //       questions: [

              //         // {
              //         //   id: '1',
              //         //   title: 'Polar bear lives in artic ocean?',
              //         //   questionType: 'dragdrop-one',
              //         //   images: ['asset:/backgrounds/question/science/howanimalsadapt/bear.png'],
              //         //   suggestionOne: 'Warm',
              //         //   suggestionTwo: 'Cold',
              //         //   suggestions: ['warm', 'cold','normal'],
              //         //   suggestionBackgrounds: [
              //         //     'red',
              //         //     'green',
              //         //     'blue',
              //         //     'orange',
              //         //   ],
              //         //   answer: 0,
              //         // },
              //         // {
              //         //   id: '2',

              //         //   questionType: 'single-select',
              //         //    title: 'Select the animal house',

              //         //   object: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgTwo: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
              //         //   answer: 2,
              //         // },

              //         {
              //           id: '1',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/plant1.png',
              //           imgTwo: 'asset:/problems/plants_animals/camel.jpg',
              //           imgThree: 'asset:/problems/plants_animals/bee.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant2.png',

              //           postion: [1, 4],
              //         },
              //         {
              //           id: '2',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/plant1.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant5.png',
              //           imgThree: 'asset:/problems/plants_animals/bee.jpg',
              //           imgFour: 'asset:/problems/plants_animals/alligator.jpg',

              //           postion: [1, 2],
              //         },
              //         {
              //           id: '3',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/plant1.png',
              //           imgTwo: 'asset:/problems/plants_animals/camel.jpg',
              //           imgThree: 'asset:/problems/plants_animals/plant4.png',
              //           imgFour: 'asset:/problems/plants_animals/plant2.png',

              //           postion: [1, 3, 4],
              //         },
              //         {
              //           id: '4',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/monkey.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/dog.jpg',
              //           imgThree: 'asset:/problems/plants_animals/bee.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant2.png',

              //           postion: [4],
              //         },

              //         {
              //           id: '5',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/plant8.png',
              //           imgTwo: 'asset:/problems/plants_animals/fish1.png',
              //           imgThree: 'asset:/problems/plants_animals/bird3.png',
              //           imgFour: 'asset:/problems/plants_animals/plant9.png',

              //           postion: [1, 4],
              //         },

              //         {
              //           id: '6',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/insect1.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant12.png',
              //           imgThree: 'asset:/problems/plants_animals/plant11.png',
              //           imgFour: 'asset:/problems/plants_animals/insect3.png',

              //           postion: [2, 3],
              //         },

              //         {
              //           id: '7',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/cat.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/plant12.png',
              //           imgThree: 'asset:/problems/plants_animals/plant11.png',
              //           imgFour: 'asset:/problems/plants_animals/plant2.png',

              //           postion: [2, 3, 4],
              //         },

              //         {
              //           id: '8',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/cat.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/plant12.png',
              //           imgThree: 'asset:/problems/plants_animals/plant11.png',
              //           imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

              //           postion: [2, 4],
              //         },

              //         {
              //           id: '9',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/plant18.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant12.png',
              //           imgThree: 'asset:/problems/plants_animals/plant11.png',
              //           imgFour: 'asset:/problems/plants_animals/monkey1.jpg',

              //           postion: [1, 2, 3],
              //         },

              //         {
              //           id: '10',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-selectfour',
              //           imgOne: 'asset:/problems/plants_animals/deer.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/fish3.png',
              //           imgThree: 'asset:/problems/plants_animals/hen.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant16.png',

              //           postion: [1],
              //         },

              //         // {
              //         //   id: '3',
              //         //   title: 'Select all the living things ',
              //         //   questionType: 'multi-select',
              //         //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgTwo: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgFour: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgFive: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgSix: 'asset:/backgrounds/background_before_home.jpg',

              //         //   postion: [1, 2, 4, 6],
              //         // },

              //         // {
              //         //   id: '4',
              //         //   title: 'Select all the Plants ',
              //         //   questionType: 'multi-selectfour',
              //         //   imgOne: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgTwo:'asset:/backgrounds/background_before_home.jpg',
              //         //   imgThree: 'asset:/backgrounds/background_before_home.jpg',
              //         //   imgFour: 'asset:/backgrounds/background_before_home.jpg',

              //         //   postion: [1, 4],
              //         // },

              //       ],
              //     },

              //     // Level 5
              //     {
              //       levelName: 5,
              //       numberOfQuestion: 10,

              //       questions: [
              //         {
              //           id: '1',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/deer.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/fish3.png',
              //           imgThree: 'asset:/problems/plants_animals/hen.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant16.png',
              //           imgFive: 'asset:/problems/plants_animals/hen.jpg',
              //           imgSix: 'asset:/problems/plants_animals/plant16.png',

              //           postion: [4, 6],
              //         },

              //         {
              //           id: '2',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/elephant.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/fish3.png',
              //           imgThree: 'asset:/problems/plants_animals/hen.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant17.png',
              //           imgFive: 'asset:/problems/plants_animals/plant18.png',
              //           imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

              //           postion: [4, 5],
              //         },

              //         {
              //           id: '3',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant1.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant2.png',
              //           imgThree: 'asset:/problems/plants_animals/plant3.png',
              //           imgFour: 'asset:/problems/plants_animals/catterpiller.jpg',
              //           imgFive: 'asset:/problems/plants_animals/bee.jpg',
              //           imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

              //           postion: [1, 2, 3],
              //         },

              //         {
              //           id: '4',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant1.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant2.png',
              //           imgThree: 'asset:/problems/plants_animals/plant3.png',
              //           imgFour: 'asset:/problems/plants_animals/catterpiller.jpg',
              //           imgFive: 'asset:/problems/plants_animals/plant6.png',
              //           imgSix: 'asset:/problems/plants_animals/monkey1.jpg',

              //           postion: [1, 2, 3, 5],
              //         },

              //         {
              //           id: '5',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/deer.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/dog.jpg',
              //           imgThree: 'asset:/problems/plants_animals/fish2.png',
              //           imgFour: 'asset:/problems/plants_animals/plant3.png',
              //           imgFive: 'asset:/problems/plants_animals/duck.jpg',
              //           imgSix: 'asset:/problems/plants_animals/plant5.png',

              //           postion: [4, 6],
              //         },

              //         {
              //           id: '6',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/deer.jpg',
              //           imgTwo: 'asset:/problems/plants_animals/plant8.png',
              //           imgThree: 'asset:/problems/plants_animals/fish2.png',
              //           imgFour: 'asset:/problems/plants_animals/plant3.png',
              //           imgFive: 'asset:/problems/plants_animals/duck.jpg',
              //           imgSix: 'asset:/problems/plants_animals/plant5.png',

              //           postion: [2, 4, 6],
              //         },

              //         {
              //           id: '7',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant12.png',
              //           imgTwo: 'asset:/problems/plants_animals/insect2.png',
              //           imgThree: 'asset:/problems/plants_animals/fish3.png',
              //           imgFour: 'asset:/problems/plants_animals/insect4.png',
              //           imgFive: 'asset:/problems/plants_animals/fish2.png',
              //           imgSix: 'asset:/problems/plants_animals/plant9.png',

              //           postion: [1, 6],
              //         },

              //         {
              //           id: '8',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant10.png',
              //           imgTwo: 'asset:/problems/plants_animals/insect2.png',
              //           imgThree: 'asset:/problems/plants_animals/fish3.png',
              //           imgFour: 'asset:/problems/plants_animals/fox.jpg',
              //           imgFive: 'asset:/problems/plants_animals/fish2.png',
              //           imgSix: 'asset:/problems/plants_animals/eagle.png',

              //           postion: [1],
              //         },

              //         {
              //           id: '9',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant10.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant11.png',
              //           imgThree: 'asset:/problems/plants_animals/plant12.png',
              //           imgFour: 'asset:/problems/plants_animals/plant13.png',
              //           imgFive: 'asset:/problems/plants_animals/plant15.png',
              //           imgSix: 'asset:/problems/plants_animals/eagle.png',

              //           postion: [6],
              //         },

              //         {
              //           id: '10',
              //           title: 'Select all the Plants ',
              //           questionType: 'multi-select',
              //           imgOne: 'asset:/problems/plants_animals/plant10.png',
              //           imgTwo: 'asset:/problems/plants_animals/plant11.png',
              //           imgThree: 'asset:/problems/plants_animals/cow.jpg',
              //           imgFour: 'asset:/problems/plants_animals/plant13.png',
              //           imgFive: 'asset:/problems/plants_animals/dolphen.jpg',
              //           imgSix: 'asset:/problems/plants_animals/eagle.png',

              //           postion: [3, 5, 6],
              //         },

              //       ],
              //     },

              //   ],

              // },
            ],
          },

          ////////////////////////////   plants questions grade 1 ////////////////////////

          {
            categoryName: 'plants',
            categoryId: '0002',
            topic: [
              // How plants adopt  1

              {
                key: '2',
                topicName: 'How Plants Adapt',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the water lilies live ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['air', 'soil', 'water'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '2',
                        title: 'They need _____ from sun.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['air', 'light', 'energy'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '3',
                        title: 'They have large , flat _______ .',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['flower', 'stem', 'leaves'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title:
                          'The leaves floaded on the  ________  in the sun.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['water', 'air', 'surface'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '5',
                        title: 'Water lilies leaves has _______ color.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/w1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['black', 'brown', 'green'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Where the cactus found ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['desert', 'forest', 'artic ocean'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'How is the desert climate ?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['warm', 'wet', 'dry'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Cactuses has very long deep _______ ',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['stem', 'leaves', 'roots'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '4',
                        title: 'They find ______ deep in ground.',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['food', 'water', 'air'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'The color of the cactus is _______ .',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/c1.png'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['white', 'green', 'blue'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },
                    ],
                  },
                ],
              },

              // Plants Life cycle

              {
                key: '5',
                topicName: 'Plants life cycle',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/seeds.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['plant', 'seed', 'fruit'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '2',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/sprouts.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['plant', 'fruit', 'sprout'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'What does cow eat?',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/plant.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['plant', 'fruit', 'seed'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/flower.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['seed', 'flower', 'sprout'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 1,
                      },

                      {
                        id: '5',
                        title: 'Choose the right answer',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/ocean/fruits.jpg'],
                        suggestionOne: 'Warm',
                        suggestionTwo: 'Cold',
                        suggestions: ['seed', 'sprout', 'fruit'],
                        background: 'asset:/problems/ocean/fish9.gif',
                        backgroundType: 'image',
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          // Energy Sound and Light

          {
            categoryName: 'Energy',
            categoryId: '1221',
            topic: [
              {
                key: '1',
                topicName: 'People or Fuel',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '2',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/trolly.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/boat.png',
                          'asset:/problems/power/car.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/motorbike.png',
                          'asset:/problems/power/cycle.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/pushingcar.png',
                          'asset:/problems/power/bus.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/plane.png',
                          'asset:/problems/power/jym.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/plane.png',
                          'asset:/problems/power/pushingcarr.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'In which image man power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/manrope.png',
                          'asset:/problems/power/train.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'In which image fuel power is used ?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/trolly.png',
                          'asset:/problems/power/train.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                    ],
                  },

                  // level 2

                  {
                    key: '1',
                    levelName: 2,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/manrope.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '2',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/train.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '3',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/jym.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '4',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/plane.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '6',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/trolly.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '7',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/tractor.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '8',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/boat.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },

                      {
                        id: '9',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/car.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 2,
                      },

                      {
                        id: '10',
                        title: 'Select the power source used in the image',
                        questionType: 'dragdrop-one',
                        images: ['asset:/problems/power/cycle.png'],

                        suggestionOne: '1',
                        suggestionTwo: '2',
                        suggestions: ['Man power', , 'Fuel power'],
                        suggestionBackgrounds: [
                          'red',
                          'green',
                          'blue',
                          'orange',
                        ],
                        answer: 0,
                      },
                    ],
                  },

                  // level 3

                  {
                    levelName: 3,
                    numberOfQuestion: 10,
                    questions: [
                      {
                        id: '1',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [1, 4],
                      },

                      {
                        id: '2',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [2, 3],
                      },

                      {
                        id: '3',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2, 3],
                      },

                      {
                        id: '4',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [4],
                      },

                      {
                        id: '5',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/boat.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2],
                      },

                      {
                        id: '6',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/cycle.png',
                        imgTwo: 'asset:/problems/power/tractor.png',
                        imgThree: 'asset:/problems/power/car.png',
                        imgFour: 'asset:/problems/power/running.png',

                        position: [1, 4],
                      },

                      {
                        id: '7',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/trolly.png',
                        imgTwo: 'asset:/problems/power/motorbike.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/manrope.png',

                        position: [2, 3],
                      },

                      {
                        id: '8',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/manrope.png',
                        imgTwo: 'asset:/problems/power/jym.png',
                        imgThree: 'asset:/problems/power/pushingcarr.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2, 3],
                      },

                      {
                        id: '9',
                        title: 'Select all the images that uses fuel power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/pushingcar.png',
                        imgTwo: 'asset:/problems/power/running.png',
                        imgThree: 'asset:/problems/power/jym.png',
                        imgFour: 'asset:/problems/power/plane.png',

                        position: [4],
                      },

                      {
                        id: '10',
                        title: 'Select all the images that uses man power',
                        questionType: 'multi-selectfour',
                        imgOne: 'asset:/problems/power/running.png',
                        imgTwo: 'asset:/problems/power/jym.png',
                        imgThree: 'asset:/problems/power/plane.png',
                        imgFour: 'asset:/problems/power/train.png',

                        position: [1, 2],
                      },
                    ],
                  },
                ],
              },

              // Sunshine and melting

              {
                key: '2',
                topicName: 'Sunshine and',
                level: [
                  // level 1

                  {
                    key: '1',
                    levelName: 1,
                    start: 1,
                    end: 10,
                    i: 0,
                    numberOfQuestion: 10,

                    questions: [
                      {
                        id: '1',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/butter.png',
                          'asset:/problems/power/melt/rock.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '2',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/ice.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '3',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/soap.png',
                          'asset:/problems/power/melt/wood.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '4',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/marble.png',
                          'asset:/problems/power/melt/chocalate.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '5',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/icecream.png',
                          'asset:/problems/power/melt/marble.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '6',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/quarter.png',
                          'asset:/problems/power/melt/caryons.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '7',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/chocalate.png',
                          'asset:/problems/power/melt/wood.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '8',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/butter.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },

                      {
                        id: '9',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/soap.png',
                          'asset:/problems/power/melt/rock.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 1,
                      },

                      {
                        id: '10',
                        questionType: 'single-select-two',
                        title: 'Which object will melts under the sun?',
                        background: IconsAndBackground.questionBackround,
                        backgroundType: 'image',
                        images: [
                          'asset:/problems/power/melt/lego.png',
                          'asset:/problems/power/melt/icecream.png',
                        ],
                        width: ['100%', '100%'],
                        height: ['100%', '100%'],
                        answer: 2,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // //////////////////////////////////////////////    MAth  Grade 1  ////////////////////////////////////////////////////////////////////////
      {
        subjectName: 'math',

        categories: [
          {
            // count objects
            categoryName: 'Count Objects',
            categoryId: '144',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple2.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple3.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['3', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple04.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '4'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple5.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple6.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '6', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '7',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '7'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '8',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple8.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['8', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '9',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple9.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '9', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '10',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple10.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple0011.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['9', '11', '13'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple12.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['15', '11', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple6.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['6', '1', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple15.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['18', '15', '4'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '5',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple19.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['12', '19', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '6',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['16', '17', '11'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '7',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '7'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '8',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple9.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['8', '9', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '9',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['15', '19', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '10',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple18.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '18', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple22.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['19', '21', '22'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '2',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['15', '16', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple26.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['26', '21', '22'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple30.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['28', '25', '30'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple5.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['6', '5', '2'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '6',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple30.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['28', '29', '30'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple17.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '1', '17'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '8',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple9.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['8', '9', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '9',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple24.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['25', '24', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '10',
                    title: 'Count the Objects?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['5', '18', '20'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },
            ],
          },

          {
            // correct symbols
            categoryName: 'Correct Symbols',
            categoryId: '550055',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [1, 2],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [2, 5],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [4, 9],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 9],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 7],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [3, 3],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [5, 1],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 3],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [7, 9],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [10, 10],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [11, 2],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [12, 5],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [14, 9],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 18],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [9, 17],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [13, 13],
                    options: ['<', '=', '>'],
                    answer: 1,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [15, 10],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [19, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [17, 19],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [7, 10],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [11, 22],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '2',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [12, 25],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '3',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [29, 14],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '4',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [29, 18],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '5',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [19, 17],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '6',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [23, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '7',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [15, 20],
                    options: ['<', '=', '>'],
                    answer: 0,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '8',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [25, 13],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '9',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [27, 19],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },

                  {
                    id: '10',
                    questionType: 'correct-symbols',
                    title: 'Drag the correct symbols?',
                    initial: [23, 10],
                    options: ['<', '=', '>'],
                    answer: 2,
                    uri: [
                      'asset:/buttons/less.png',
                      'asset:/buttons/equal.png',
                      'asset:/buttons/greater.png',
                    ],
                  },
                ],
              },
            ],
          },

          {
            // Skip Counts
            categoryName: 'Skip Counts',
            categoryId: '8888',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['2', '8'],
                    answer: ['4', '6', '10', '12'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['2', '10'], // value ( 1 and 5 )
                    answer: ['4', '6', '8', '12'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '6',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '22'], // value ( 2 and 5 )
                    answer: ['14', '18', '20', '24'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['18', '20'],
                    answer: ['12', '14', '16', '22'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '8',

                    initial: ['32', '38'],
                    answer: ['34', '36', '40', '42'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['12', '18'],
                    answer: ['14', '16', '18', '20'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '22'], // value ( 2 and 5 )
                    answer: ['14', '18', '20', '24'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['18', '20'],
                    answer: ['12', '14', '16', '18'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['16', '20'], // value ( 1 and 3 )
                    answer: ['18', '22', '24', '26'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['8', '10'],
                    answer: ['2', '4', '6', '8'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    question: '1',

                    initial: ['32', '38'],
                    answer: ['34', '36', '40', '42'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',

                    initial: ['38', '40'],
                    answer: ['32', '34', '36', '42'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '40'], // value ( 1 and 3 )
                    answer: ['34', '32', '38', '42'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '10'], // value ( 1 and 3 )
                    answer: ['8', '12', '14', '16'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['32', '40'], // value ( 1 and 5 )
                    answer: ['34', '36', '38', '42'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['36', '32'], // value ( 2 and 5 )
                    answer: ['34', '38', '40', '42'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['22', '20'], // value ( 1 and 5 )
                    answer: ['12', '14', '16', '18'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers with difference of 2',
                    initial: ['6', '12'], // value ( 2 and 5 )
                    answer: ['4', '8', '10', '14'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },

          {
            // Count backwards
            categoryName: 'Count backwards',
            categoryId: '001',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['5', '2'],
                    answer: ['4', '3', '1', '9'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['12', '9'], // value ( 2 and 5 )
                    answer: ['13', '11', '10', '9'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['2', '1'],
                    answer: ['5', '4', '3', '6'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['16', '14'], // value ( 1 and 3 )
                    answer: ['15', '13', '12', '11'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['11', '7'], // value ( 1 and 5 )
                    answer: ['10', '9', '8', '6'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['15', '12'],
                    answer: ['14', '13', '11', '19'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['18', '15'], // value ( 2 and 5 )
                    answer: ['19', '17', '16', '9'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['12', '11'],
                    answer: ['15', '14', '13', '16'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['20', '18'], // value ( 1 and 3 )
                    answer: ['19', '17', '16', '15'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['19', '15'], // value ( 1 and 5 )
                    answer: ['18', '17', '16', '14'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['15', '12'],
                    answer: ['14', '13', '11', '19'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['22', '19'], // value ( 2 and 5 )
                    answer: ['23', '21', '20', '22'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['27', '26'],
                    answer: ['30', '29', '28', '25'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['26', '24'], // value ( 1 and 3 )
                    answer: ['25', '23', '22', '21'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['21', '17'], // value ( 1 and 5 )
                    answer: ['20', '19', '18', '16'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['5', '2'],
                    answer: ['4', '3', '1', '6'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['28', '25'], // value ( 2 and 5 )
                    answer: ['29', '27', '26', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['22', '21'],
                    answer: ['25', '24', '23', '26'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['16', '14'], // value ( 1 and 3 )
                    answer: ['15', '13', '12', '11'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['9', '5'], // value ( 1 and 5 )
                    answer: ['8', '7', '6', '4'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '1',

                    initial: ['25', '22'],
                    answer: ['24', '23', '21', '29'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['32', '39'], // value ( 2 and 5 )
                    answer: ['33', '31', '30', '34'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['37', '36'],
                    answer: ['40', '39', '38', '35'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['36', '34'], // value ( 1 and 3 )
                    answer: ['35', '33', '32', '31'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['31', '37'], // value ( 1 and 5 )
                    answer: ['30', '29', '28', '28'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    question: '6',

                    initial: ['35', '32'],
                    answer: ['34', '33', '31', '36'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['28', '25'], // value ( 2 and 5 )
                    answer: ['29', '27', '26', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },

                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',

                    initial: ['2', '1'],
                    answer: ['5', '4', '3', '6'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['26', '24'], // value ( 1 and 3 )
                    answer: ['25', '23', '22', '21'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers ',
                    initial: ['19', '15'], // value ( 1 and 5 )
                    answer: ['18', '17', '16', '14'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },

          // More less
          {
            categoryName: 'More or Less',
            categoryId: '17777',
            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple1.png',
                      'asset:/problems/object/apple2.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple3.png',
                      'asset:/problems/object/apple2.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/appple7.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple3.png',
                      'asset:/problems/object/apple5.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/appple7.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple6.png',
                      'asset:/problems/object/apple10.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple2.png',
                      'asset:/problems/object/apple8.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple9.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple5.png',
                      'asset:/problems/object/apple10.png',
                    ],
                    answer: 2,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple11.png',
                      'asset:/problems/object/apple12.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple13.png',
                      'asset:/problems/object/apple12.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple17.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple13.png',
                      'asset:/problems/object/apple15.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple17.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple16.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple12.png',
                      'asset:/problems/object/apple18.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple15.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 2,
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple22.png',
                      'asset:/problems/object/apple24.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple23.png',
                      'asset:/problems/object/apple21.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple24.png',
                      'asset:/problems/object/apple27.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple23.png',
                      'asset:/problems/object/apple25.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple27.png',
                      'asset:/problems/object/apple29.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple26.png',
                      'asset:/problems/object/apple20.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple12.png',
                      'asset:/problems/object/apple18.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple4.png',
                      'asset:/problems/object/apple19.png',
                    ],
                    answer: 2,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Select the less object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple14.png',
                      'asset:/problems/object/apple29.png',
                    ],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Select the more object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: [
                      'asset:/problems/object/apple15.png',
                      'asset:/problems/object/apple30.png',
                    ],
                    answer: 2,
                  },
                ],
              },
            ],
          },
          // Even Odd
          {
            categoryName: 'Even Odd',
            categoryId: '55',

            level: [
              // level 1
              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple1.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple2.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple3.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple04.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple8.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple10.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple9.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple6.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple11.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple12.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple13.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple04.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple18.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple19.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },

              // level 3
              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple21.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '2',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple26.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                  {
                    id: '3',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple23.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple29.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple18.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple27.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '7',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple20.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '8',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple19.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '9',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/apple16.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '10',
                    title: 'Even or Odd',
                    questionType: 'dragdrop-one',
                    images: ['asset:/problems/object/appple7.png'],

                    // suggestionOne: 'Warm',
                    // suggestionTwo: 'Cold',
                    suggestions: ['Even', '', 'Odd'],
                    suggestionBackgrounds: ['red', '', 'blue', 'orange'],
                    answer: 2,
                  },
                ],
              },
            ],
          },
          // Missing Numbers
          {
            categoryName: 'Missing Numbers',
            categoryId: '5055',
            level: [
              // level 1

              {
                key: '1',
                levelName: 1,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['1', '4'],
                    answer: ['2', '3', '5', '6'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['6', '9'], // value ( 2 and 5 )
                    answer: ['5', '7', '8', '10'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['7', '8'],
                    answer: ['4', '5', '6', '9'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '5'], // value ( 1 and 2 )
                    answer: ['4', '6', '7', '8'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['5', '9'], // value ( 1 and 5 )
                    answer: ['6', '7', '8', '10'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['3', '6'],
                    answer: ['4', '5', '7', '8'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '6'], // value ( 2 and 5 )
                    answer: ['2', '4', '5', '7'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['9', '10'],
                    answer: ['6', '7', '8', '11'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['3', '5'], // value ( 1 and 2 )
                    answer: ['4', '6', '7', '8'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['4', '8'], // value ( 1 and 5 )
                    answer: ['5', '6', '7', '9'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 2

              {
                key: '1',
                levelName: 2,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['11', '14'],
                    answer: ['12', '13', '15', '16'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['16', '19'], // value ( 2 and 5 )
                    answer: ['15', '17', '18', '20'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['17', '18'],
                    answer: ['14', '15', '16', '19'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '15'], // value ( 1 and 2 )
                    answer: ['14', '16', '17', '18'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['15', '19'], // value ( 1 and 5 )
                    answer: ['16', '17', '18', '20'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['9', '13'],
                    answer: ['10', '11', '12', ' 14'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '16'], // value ( 2 and 5 )
                    answer: ['12', '14', '15', '17'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['19', '20'],
                    answer: ['16', '17', '18', '21'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '15'], // value ( 1 and 2 )
                    answer: ['14', '16', '17', '18'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['14', '18'], // value ( 1 and 5 )
                    answer: ['15', '16', '17', '19'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },

              // level 3

              {
                key: '1',
                levelName: 3,
                start: 0,
                end: 10,
                numberOfQuestion: 9,

                questions: [
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '1',

                    initial: ['21', '24'],
                    answer: ['22', '23', '25', '26'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '2',
                    questionType: 'missing-number',
                    title: 'MissFill the missing numbersing',
                    initial: ['26', '29'], // value ( 2 and 5 )
                    answer: ['25', '27', '28', '30'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '3',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['27', '28'],
                    answer: ['24', '25', '26', '29'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '4',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['23', '25'], // value ( 1 and 2 )
                    answer: ['24', '26', '27', '28'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '5',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['15', '19'], // value ( 1 and 5 )
                    answer: ['16', '17', '18', '20'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    question: '6',

                    initial: ['3', '6'],
                    answer: ['4', '5', '6', '7'],
                    layout: 'one', // value ( 1 and 4 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '7',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['13', '16'], // value ( 2 and 5 )
                    answer: ['12', '14', '15', '17'],
                    layout: 'two',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '8',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',

                    initial: ['29', '20'],
                    answer: ['26', '27', '28', '29'],
                    layout: 'three', // value ( 4 and 5 )
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '9',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['23', '25'], // value ( 1 and 2 )
                    answer: ['24', '26', '27', '28'],
                    layout: 'four',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    question: '10',
                    questionType: 'missing-number',
                    title: 'Fill the missing numbers',
                    initial: ['24', '28'], // value ( 1 and 5 )
                    answer: ['25', '26', '27', '29'],
                    layout: 'five',
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                ],
              },
            ],
          },
          // least_Greatest
          {
            categoryName: 'Least to Greatest',
            categoryId: '484890',
            level: [
              // level 1

              {
                key: '1',
                levelName: 1,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [5, 3, 2, 1, 4, 6],
                    answer: [1, 2, 3, 4, 5, 6],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [8, 3, 5, 4, 7, 6],
                    answer: [3, 4, 5, 6, 7, 8],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [9, 10, 6, 8, 7, 5],
                    answer: [5, 6, 7, 8, 9, 10],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [15, 11, 16, 12, 14, 13],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [12, 7, 11, 8, 10, 9],
                    answer: [7, 8, 9, 10, 11, 12],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [15, 13, 12, 11, 14, 16],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [18, 13, 15, 14, 17, 16],
                    answer: [13, 14, 15, 16, 17, 18],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [19, 20, 16, 18, 17, 15],
                    answer: [15, 16, 17, 18, 19, 20],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [25, 21, 26, 22, 24, 23],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },

              // level 2

              {
                key: '1',
                levelName: 2,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [15, 13, 12, 11, 14, 16],
                    answer: [11, 12, 13, 14, 15, 16],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [18, 13, 15, 14, 17, 16],
                    answer: [13, 14, 15, 16, 17, 18],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [19, 20, 16, 18, 17, 15],
                    answer: [15, 16, 17, 18, 19, 20],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [25, 21, 26, 22, 24, 23],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [25, 23, 22, 21, 24, 26],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [28, 23, 25, 24, 27, 26],
                    answer: [23, 24, 25, 26, 27, 28],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [29, 30, 26, 28, 27, 25],
                    answer: [25, 26, 27, 28, 29, 30],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [35, 31, 36, 32, 34, 33],
                    answer: [31, 32, 23, 34, 35, 36],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [32, 27, 31, 28, 30, 29],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },

              // level 3

              {
                key: '1',
                levelName: 3,

                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [25, 23, 22, 21, 24, 26],
                    answer: [21, 22, 23, 24, 25, 26],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '2',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [28, 23, 25, 24, 27, 26],
                    answer: [23, 24, 25, 26, 27, 28],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '3',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [29, 30, 26, 28, 27, 25],
                    answer: [25, 26, 27, 28, 29, 30],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '4',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [35, 31, 36, 32, 34, 33],
                    answer: [31, 32, 33, 34, 35, 36],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '5',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [42, 37, 41, 38, 40, 39],
                    answer: [37, 38, 39, 40, 41, 42],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '6',
                    questionType: 'least-greatest',
                    title: 'Arange the Numbers from least to greatest',
                    initial: [35, 33, 32, 31, 34, 36],
                    answer: [31, 32, 33, 34, 35, 36],
                    background: '#27A1E3',
                    backgroundType: 'color',
                  },
                  {
                    id: '7',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [38, 33, 35, 34, 37, 36],
                    answer: [33, 34, 35, 36, 37, 38],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '8',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [39, 40, 36, 38, 37, 35],
                    answer: [35, 36, 37, 38, 39, 40],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '9',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [5, 1, 6, 2, 4, 3],
                    answer: [1, 3, 2, 3, 5, 6],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },

                  {
                    id: '10',
                    questionType: 'least-greatest',
                    title: 'Least to Greatest',
                    initial: [22, 17, 21, 18, 20, 19],
                    answer: [17, 18, 19, 20, 21, 22],
                    background: 'image',
                    backgroundType: IconsAndBackground.questionBackround,
                  },
                ],
              },
            ],
          },
          // Addition
          {
            categoryName: 'Addition',
            categoryId: '92478',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [2, 2],
                    options: [5, 3, 4],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [2, 7],
                    options: [6, 9, 2],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 2],
                    options: [3, 2, 7],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [1, 5],
                    options: [6, 8, 7],
                    answer: 0,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [4, 5],
                    options: [6, 9, 8],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [2, 3],
                    options: [8, 3, 5],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 5],
                    options: [3, 9, 10],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [3, 2],
                    options: [2, 5, 10],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [1, 1],
                    options: [3, 6, 2],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 1],
                    options: [4, 6, 8],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [12, 2],
                    options: [15, 13, 14],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [12, 7],
                    options: [16, 19, 12],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 12],
                    options: [13, 12, 17],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [11, 5],
                    options: [16, 18, 17],
                    answer: 0,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [4, 15],
                    options: [16, 19, 18],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 10],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [2, 13],
                    options: [18, 13, 15],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 15],
                    options: [13, 19, 20],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [13, 2],
                    options: [12, 15, 10],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [1, 11],
                    options: [13, 16, 12],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 11],
                    options: [14, 16, 18],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [12, 12],
                    options: [25, 23, 24],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [2, 27],
                    options: [26, 29, 22],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [15, 12],
                    options: [23, 22, 27],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [11, 15],
                    options: [26, 28, 27],
                    answer: 0,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [14, 15],
                    options: [26, 29, 28],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [16, 13],
                    options: [23, 29, 30],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [12, 13],
                    options: [28, 23, 25],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [15, 15],
                    options: [23, 29, 30],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [13, 12],
                    options: [22, 25, 20],
                    answer: 1,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [11, 11],
                    options: [23, 26, 22],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'Add the Numbers?',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [15, 11],
                    options: [24, 26, 28],
                    answer: 2,
                    operator: '+',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },
            ],
          },

          // subtraction
          {
            categoryName: 'subtraction',
            categoryId: '1110011',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 2],
                    options: [5, 3, 4],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [7, 2],
                    options: [5, 9, 2],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [5, 4],
                    options: [2, 1, 3],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [10, 5],
                    options: [6, 5, 8],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [8, 2],
                    options: [5, 3, 6],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [9, 2],
                    options: [7, 9, 2],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [10, 4],
                    options: [5, 6, 7],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [8, 5],
                    options: [6, 5, 3],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [6, 3],
                    options: [3, 9, 10],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [15, 2],
                    options: [15, 13, 14],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [17, 2],
                    options: [15, 19, 12],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [15, 4],
                    options: [12, 11, 13],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [20, 5],
                    options: [16, 15, 18],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 20],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [18, 2],
                    options: [15, 13, 16],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [19, 2],
                    options: [17, 19, 12],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [20, 4],
                    options: [15, 16, 17],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [18, 5],
                    options: [16, 15, 13],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [16, 3],
                    options: [13, 19, 20],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [25, 2],
                    options: [25, 23, 24],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '2',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [27, 2],
                    options: [25, 29, 22],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '3',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [25, 14],
                    options: [7, 2, 11],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '4',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [20, 15],
                    options: [6, 5, 8],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '5',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [26, 23],
                    options: [3, 2, 4],
                    answer: 0,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '6',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [28, 19],
                    options: [21, 21, 11],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '7',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [29, 20],
                    options: [7, 9, 2],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '8',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [20, 14],
                    options: [5, 6, 7],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '9',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [28, 15],
                    options: [26, 25, 13],
                    answer: 2,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },

                  {
                    id: '10',
                    questionType: 'add-minus',
                    title: 'subtract the Numbers',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    initial: [26, 13],
                    options: [23, 13, 10],
                    answer: 1,
                    operator: '-',
                    background: ['red', 'green', 'blue'],
                  },
                ],
              },
            ],
          },

          // Bar chart
          {
            categoryName: 'Barchart',
            categoryId: '11100111',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like footbal',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['25', '30', '35'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like globe',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['25', '35', '45'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like rocket',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['35', '45', '50'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like car',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/14.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['35', '45', '50'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like ball',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '6'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like bat',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '5'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    title: 'How many kids like pencil',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '10', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    title: 'How many kids like clock',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '8', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'How many kids like plane',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '6'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                  {
                    id: '2',
                    title: 'How many kids like car',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['3', '4', '5'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'How many kids like bus',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['9', '10', '12'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '3',
                    title: 'How many kids like bike',
                    questionType: 'dragdrop-one',
                    images: ['asset:/raw/barcharts/5.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['5', '8', '10'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },
            ],
          },

          // True False
          {
            categoryName: 'True and False',
            categoryId: '09000',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['12  +  12  =  24'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['6  -  3  =  3'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['6  +  3  =  9'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['7  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['12  +  6  =  24'],

                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  6  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  3  =  13'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  +  3  =  19'],

                    answer: 0,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  5  =  15'],

                    answer: 1,
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  8  =  16'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['10  +  6  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  3  =  13'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  3  =  11'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['8  +  6  =  14'],

                    answer: 0,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['13  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  -  13  =  3'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['16  +  13  =  19'],

                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['17  -  15  =  15'],

                    answer: 1,
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  8  =  26'],

                    answer: 0,
                  },

                  {
                    id: '2',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['10  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  -  13  =  13'],

                    answer: 0,
                  },

                  {
                    id: '4',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  13  =  31'],

                    answer: 0,
                  },

                  {
                    id: '5',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['27  -  5  =  5'],

                    answer: 1,
                  },

                  {
                    id: '6',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['18  +  6  =  24'],

                    answer: 0,
                  },

                  {
                    id: '7',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['23  +  16  =  14'],

                    answer: 1,
                  },

                  {
                    id: '8',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  -  23  =  3'],

                    answer: 0,
                  },

                  {
                    id: '9',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['26  +  13  =  19'],

                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'true-false',
                    title: 'Drag the right answer?',
                    options: ['True', 'False'],
                    statement: ['27  -  15  =  15'],

                    answer: 1,
                  },
                ],
              },
            ],
          },

          // Word Problems
          {
            categoryName: 'Word Problems',
            categoryId: '0100',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'word-problems',
                    title:
                      'Json purchased 7 KG of sugar and 4 KG of rice.What is the total weight Json carried?',
                    initial: [1, 2],
                    options: ['4', '7', '11'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      'Three red marbles and three green marbles are on the table.How many marbles are on the tables ?',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'Three red marbles and three green marbles are on the table.How many marbles are on the tables ?',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'There are four small candies and five big candies in the box.How many candies are there altogether?',
                    initial: [1, 2],
                    options: ['5', '4', '9'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'Ava has 5 flowers and david give her 6 more. How many flowers does she have now ?',
                    initial: [1, 2],
                    options: ['5', '6', '11'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'One farmer has 10 lambs and other have 13. How many lambs do the two farmers have together?',
                    initial: [1, 2],
                    options: ['13', '10', '23'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      '6 people are on the bus. 4 more people get on the bus. How many people are on the bsu now?',
                    initial: [1, 2],
                    options: ['6', '4', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      'Sam has 5 blocks. He found 4 more.How many blocks does he have now?',
                    initial: [1, 2],
                    options: ['4', '5', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      'There are 12 aeroplanes in an airport. 6 more land.How many aeroplanes in total?',
                    initial: [1, 2],
                    options: ['12', '6', '18'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'There are 10 fishes , 7 more come along. How many fishes altogether?',
                    initial: [1, 2],
                    options: ['10', '7', '17'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },

              //  level 2

              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'word-problems',
                    title:
                      'I have 2 books and my friend has 4 books. How many books are there total? ',
                    initial: [1, 2],
                    options: ['2', '4', '6'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      'Marry has 3 bananas anf 4 cherries. How many fruits does she have in all?',
                    initial: [1, 2],
                    options: ['3', '4', '7'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'joe has 5 ballons and barry have 6 . How many ballon they have in all?                    ',
                    initial: [1, 2],
                    options: ['5', '6', '11'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'There are 7 men 7 woman in the bus. How many person are there in the bus?',
                    initial: [1, 2],
                    options: ['7', '7', '14'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'There are 14 boys and 13 girls in a class. How many students are there  in a class?',
                    initial: [1, 2],
                    options: ['14', '13', '27'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'Sam has 6 blue  and 7 red balls. How many balls does he have ?',
                    initial: [1, 2],
                    options: ['6', '7', '13'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      '6 people are on the bus. 4 more people get on the bus. How many people are on the bus now?',
                    initial: [1, 2],
                    options: ['6', '4', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      'Sally has 12 pens. and frazer has 8. How many pens are there in total ?',
                    initial: [1, 2],
                    options: ['12', '8', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      'Lilly bought 8 pencils and pam bought 7. How many penils they have together ?',
                    initial: [1, 2],
                    options: ['8', '7', '15'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'In a jungle there are 15 elephants and 10 deers. How many animals are there in jungle?',
                    initial: [1, 2],
                    options: ['15', '10', '25'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },

              //  level 3

              {
                key: '1',
                levelName: 3,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'word-problems',
                    title:
                      'Tom has 3 yellow pencils and 3 red pencils. How many pencils does he have? ',
                    initial: [1, 2],
                    options: ['3', '3', '6'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '2',
                    questionType: 'word-problems',
                    title:
                      ' There are 4 roses and 3 sunflower in my garden. How many flowers are there in all?',
                    initial: [1, 2],
                    options: ['3', '4', '7'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '3',
                    questionType: 'word-problems',
                    title:
                      'There are 5 oranges slices in plate.john adds 4 more. How many orange slices are there in the palte now ?                  ',
                    initial: [1, 2],
                    options: ['5', '4', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '4',
                    questionType: 'word-problems',
                    title:
                      'Tina has 5 candies / Rosy has 4 candies. how many candies they have all?',
                    initial: [1, 2],
                    options: ['4', '5', '9'],
                    answer: [1, 0, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '5',
                    questionType: 'word-problems',
                    title:
                      'There are 6 birds sitting on a tree. 3 more come there. How many birds are there in all?',
                    initial: [1, 2],
                    options: ['6', '3', '9'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '6',
                    questionType: 'word-problems',
                    title:
                      'john had 7 coins. His father gave him 3 more. how many coins does john has now ?',
                    initial: [1, 2],
                    options: ['7', '3', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '7',
                    questionType: 'word-problems',
                    title:
                      'Lilly bought 8 pencils and pam bought 7. How many penils they have togethere?',
                    initial: [1, 2],
                    options: ['7', '8', '15'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '8',
                    questionType: 'word-problems',
                    title:
                      ' There are 8 cats on the and 7 on the ground. How many cats are there ?',
                    initial: [1, 2],
                    options: ['8', '7', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '9',
                    questionType: 'word-problems',
                    title:
                      ' Rahul took 15 steps. then he took 5 more steps . How many steps he walked in all ?',
                    initial: [1, 2],
                    options: ['15', '5', '20'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },

                  {
                    id: '10',
                    questionType: 'word-problems',
                    title:
                      'Sara ate slice of 4 pizza  Jack ate 6. how many slices of pizza did they eat together',
                    initial: [1, 2],
                    options: ['4', '6', '10'],
                    answer: [0, 1, 2],
                    background: ['red', 'green', 'blue', 'orange'],
                  },
                ],
              },
            ],
          },

          // Time
          {
            categoryName: 'Time',
            categoryId: '010010111',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/4.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '03:00', '04:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/6.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['06:00', '07:00', '08:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/9.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['11:00', '10:00', '09:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/12.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['12:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/3.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['03:00', '01:00', '02:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '7',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/8.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['09:00', '08:00', '10:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '8',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/9.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['09:00', '010:00', '08:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '9',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/7.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['05:00', '06:00', '07:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '10',
                    title: 'What time is it?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/11.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['11:00', '12:00', '01:00'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },
                ],
              },

              // level 2
              {
                key: '1',
                levelName: 2,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 9,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Which clock shows two oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/1.png', 'asset:/time1/2.png'],
                    answer: 2,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Which clock shows three oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/3.png', 'asset:/time1/2.png'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Which clock shows four oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/3.png', 'asset:/time/4.png'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Which clock shows seven oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/7.png', 'asset:/time1/9.png'],
                    answer: 1,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Which clock shows ten oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/7.png', 'asset:/time/10.png'],
                    answer: 2,
                  },

                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Which clock shows one oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/1.png', 'asset:/time/12.png'],
                    answer: 1,
                  },

                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Which clock shows six oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/12.png', 'asset:/time1/6.png'],
                    answer: 2,
                  },

                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Which clock shows nine oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/9.png', 'asset:/time1/6.png'],
                    answer: 1,
                  },

                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Which clock shows eleven oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time/11.png', 'asset:/time1/5.png'],
                    answer: 1,
                  },

                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Which clock shows ten oclock',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/time1/12.png', 'asset:/time/10.png'],
                    answer: 2,
                  },
                ],
              },
            ],
          },

          // Ordinal numbers
          {
            categoryName: 'Ordinal',
            categoryId: '33001100',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    title: 'At which position A.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['2nd', '3rd', '4th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    title: 'At which position B.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['6th', '7th', '8th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '3',
                    title: 'At which position C.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['1st', '2nd', '3rd'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '4',
                    title: 'At which position D.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['1st', '2nd', '3rd'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '5',
                    title: 'At which position E.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 2,
                  },

                  {
                    id: '6',
                    title: 'At which position F.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 0,
                  },

                  {
                    id: '6',
                    title: 'At which position H.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '6th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },

                  {
                    id: '6',
                    title: 'At which position G.. lies?',
                    questionType: 'dragdrop-one',
                    images: ['asset:/time/a1.png'],

                    suggestionOne: '1',
                    suggestionTwo: '2',
                    suggestions: ['4th', '5th', '7th'],
                    suggestionBackgrounds: ['red', 'green', 'blue', 'orange'],
                    answer: 1,
                  },
                ],
              },
            ],
          },

          // Comparison
          {
            categoryName: 'Comparison',
            categoryId: '0210',
            level: [
              //  level 1

              {
                key: '1',
                levelName: 1,
                start: 1,
                end: 10,
                i: 0,
                numberOfQuestion: 10,

                questions: [
                  {
                    id: '1',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/star.png', 'asset:/star.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },

                  {
                    id: '2',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/4.png', 'asset:/8.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },

                  {
                    id: '3',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/2.png', 'asset:/2.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },

                  {
                    id: '4',
                    questionType: 'single-select-two',
                    title: 'Slect the small object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/3.png', 'asset:/3.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 2,
                  },

                  {
                    id: '5',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/4.png', 'asset:/4.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '6',
                    questionType: 'single-select-two',
                    title: 'Slect the small object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/5.png', 'asset:/5.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 2,
                  },
                  {
                    id: '7',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/6.png', 'asset:/6.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '8',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/7.png', 'asset:/7.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },
                  {
                    id: '9',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/8.png', 'asset:/8.png'],
                    width: ['50%', '100%'],
                    height: ['50%', '100%'],
                    answer: 2,
                  },
                  {
                    id: '10',
                    questionType: 'single-select-two',
                    title: 'Slect the big object',
                    background: IconsAndBackground.questionBackround,
                    backgroundType: 'image',
                    images: ['asset:/9.png', 'asset:/9.png'],
                    width: ['100%', '50%'],
                    height: ['100%', '50%'],
                    answer: 1,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];


export const shortcodes = [
  {
    name: 'oneDescriptionImageAndFourTextOptions',
    shotcodes: [
      "d1-t-d2-0-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-t-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-t-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-0-d2-t-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-0-d2-t-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-0-d2-t-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-t-o1-0-o2-t-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-i-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-0-d2-i-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-0-d2-i-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-0-d2-i-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-i-o1-0-o2-t-o3-t-o4-t-o5-t-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-0-o4-0-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-t-o4-0-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-0-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-0-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-0-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-0-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-0-o3-t-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-0-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-t-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-0-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-0-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-0-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-0-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-0-o3-t-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-0-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-t-o3-0-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-t-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-t-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-t-o2-0-o3-0-o4-t-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-t-d2-0-o1-0-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-0-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-t-o3-0-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-t-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-t-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-t-o2-0-o3-0-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-i-d2-0-o1-0-o2-0-o3-t-o4-t-o5-t-o6-0"
    ]
  },
  {
    name: 'noDescriptionAndTwoImageOptions',
    shortcode: [
      "d1-0-d2-0-o1-i-o2-i-o3-0-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-0-o3-i-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-i-o6-0",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-0-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-0-o2-i-o3-0-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-0-o2-i-o3-0-o4-0-o5-i-o6-0",
      "d1-0-d2-0-o1-0-o2-i-o3-0-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-0-o2-0-o3-i-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-0-o2-0-o3-i-o4-0-o5-i-o6-0",
      "d1-0-d2-0-o1-0-o2-0-o3-i-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-0-o2-0-o3-0-o4-i-o5-i-o6-0",
      "d1-0-d2-0-o1-0-o2-0-o3-0-o4-i-o5-0-o6-i",
      "d1-0-d2-0-o1-0-o2-0-o3-0-o4-0-o5-i-o6-i"
    ]
  },
  // {
  //   name: 'noDescriptionAndFourTextOptions',
  //   shortcode: [
  //     "d1-0-d2-0-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
  //     "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
  //     "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-t",
  //     "d1-0-d2-0-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
  //     "d1-0-d2-0-o1-t-o2-t-o3-0-o4-0-o5-t-o6-t",
  //     "d1-0-d2-0-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
  //     "d1-0-d2-0-o1-t-o2-0-o3-t-o4-0-o5-t-o6-t",
  //     "d1-0-d2-0-o1-t-o2-0-o3-0-o4-t-o5-t-o6-t",
  //     "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-t-o6-t",
  //     "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-0-o6-t",
  //     "d1-0-d2-0-o1-0-o2-t-o3-t-o4-t-o5-t-o6-0",
  //     "d1-0-d2-0-o1-0-o2-t-o3-t-o4-t-o5-0-o6-t",
  //     "d1-0-d2-0-o1-0-o2-t-o3-t-o4-0-o5-t-o6-t",
  //     "d1-0-d2-0-o1-0-o2-t-o3-0-o4-t-o5-t-o6-0",
  //     "d1-0-d2-0-o1-0-o2-0-o3-t-o4-t-o5-t-o6-t",
  //     "d1-0-d2-0-o1-0-o2-0-o3-0-o4-t-o5-t-o6-t",
  //     "d1-0-d2-0-o1-0-o2-0-o3-0-o4-0-o5-t-o6-t",
  //     'd1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-0'
  //   ]
  // },

  {
    name: 'noDescriptionAndFourOrSixTextOrImageOptions',
    shortcode: [
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-t-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-t-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-t",
      "d1-0-d2-0-o1-t-o2-t-o3-0-o4-t-o5-t-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-0-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-0-o1-t-o2-0-o3-t-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-t-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-0-o6-t",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-t-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-t-o5-t-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-t-o5-0-o6-t",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-t-o3-0-o4-t-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-t-o4-t-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-t-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-t-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-t-o6-t",
      "d1-0-d2-0-o1-t-o2-0-o3-0-o4-0-o5-0-o6-t",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-0-o5-i-o6-0",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-i-o2-i-o3-0-o4-i-o5-i-o6-0",
      "d1-0-d2-0-o1-i-o2-i-o3-0-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-i-o4-i-o5-i-o6-0",
      "d1-0-d2-0-o1-i-o2-0-o3-i-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-i-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-i-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-i-o5-i-o6-0",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-i-o5-0-o6-i",
      "d1-0-d2-0-o1-i-o2-i-o3-i-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-i-o3-0-o4-i-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-i-o4-i-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-i-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-i-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-i-o6-i",
      "d1-0-d2-0-o1-i-o2-0-o3-0-o4-0-o5-0-o6-i",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-i-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-t-o3-t-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-i-o3-t-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-i-o3-t-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-t-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-i-o4-i-o5-0-o6-0",
      "d1-0-d2-0-o1-i-o2-t-o3-i-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-0-d2-0-o1-t-o2-t-o3-i-o4-i-o5-i-o6-0"
    ]
  },
  {
    name: 'oneDescriptionTextAndFourOrThreeOrTwoImagesInOption',
    shortcode: [
      'd1-t-d2-0-o1-i-o2-i-o3-0-o4-0-o5-0-o6-0',
      'd1-0-d2-t-o1-i-o2-i-o3-0-o4-0-o5-0-o6-0',
      'd1-t-d2-0-o1-i-o2-0-o3-i-o4-0-o5-0-o6-0',
      'd1-0-d2-t-o1-i-o2-0-o3-i-o4-0-o5-0-o6-0',
      'd1-t-d2-0-o1-0-o2-i-o3-i-o4-0-o5-0-o6-0',
      'd1-0-d2-t-o1-0-o2-i-o3-i-o4-0-o5-0-o6-0',
      'd1-t-d2-0-o1-i-o2-i-o3-i-o4-0-o5-0-o6-0',
      'd1-0-d2-t-o1-i-o2-i-o3-i-o4-0-o5-0-o6-0',
      'd1-t-d2-0-o1-i-o2-i-o3-0-o4-i-o5-0-o6-0',
      'd1-0-d2-t-o1-i-o2-i-o3-0-o4-i-o5-0-o6-0',
      'd1-t-d2-0-o1-0-o2-i-o3-i-o4-i-o5-0-o6-0',
      'd1-0-d2-t-o1-0-o2-i-o3-i-o4-i-o5-0-o6-0',
      'd1-t-d2-0-o1-i-o2-i-o3-i-o4-i-o5-0-o6-0',
      'd1-0-d2-t-o1-i-o2-i-o3-i-o4-i-o5-0-o6-0',
      'd1-t-d2-0-o1-i-o2-i-o3-i-o4-0-o5-i-o6-0',
      'd1-0-d2-t-o1-i-o2-i-o3-i-o4-0-o5-i-o6-0',
      'd1-t-d2-0-o1-i-o2-i-o3-i-o4-i-o5-0-o6-0',
      'd1-0-d2-0-o1-i-o2-i-o3-i-o4-i-o5-0-o6-0',

    ]
  },
  {
    name: 'oneDescriptionImageAndFourOrThreeImagesInOption',
    shortcode: [
      "d1-i-d2-0-o1-i-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-i-o2-i-o3-0-o4-i-o5-0-o6-0",
      "d1-i-d2-0-o1-i-o2-i-0-o3-i-o4-0-o5-0-o6-0",
      "d1-i-d2-0-o1-i-o2-0-o3-i-o4-i-o5-0-o6-0",
      "d1-i-d2-0-o1-i-o2-0-o3-0-o4-i-o5-i-o6-0",
      "d1-i-d2-0-o1-i-0-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-i-d2-t-o1-i-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-i-d2-t-o1-i-o2-i-o3-0-o4-i-o5-0-o6-0",
      "d1-i-d2-t-o1-i-o2-i-0-o3-i-o4-0-o5-0-o6-0",
      "d1-i-d2-t-o1-i-o2-0-o3-i-o4-i-o5-0-o6-0",
      "d1-t-d2-i-o1-i-o2-i-o3-i-o4-0-o5-0-o6-0",
      "d1-t-d2-i-o1-i-o2-i-o3-0-o4-i-o5-0-o6-0",
      "d1-t-d2-i-o1-i-o2-0-o3-i-o4-i-o5-0-o6-0",
      "d1-t-d2-i-o1-i-0-o2-i-o3-i-o4-0-o5-0-o6-0"
    ]

  },


]


