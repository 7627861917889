<template>
    <div class="pt-2 pb-3 missing-elements">
      <!-- <div class="question-text">
        {{ currentQuestion }}
      </div> -->
  
      <div class="d-flex justify-content-center mb-2">
          <div class="btn__expressions" v-if="this.currentQuestion._id">
            <span v-for="(e, eIndex) in expressions" :key="eIndex" class="">
                <span v-if="e.type == 'question'" @click="selectQuestion(e)">
                    <button v-if="e.expression != '__'" :class="`btn btn__white ${e.type} btn___${e.btn} ${currentQuestionIndex === e.optionsGroupsIndex ? 'vertical-line':''}`">
                        {{ e.expression }}
                    </button>
                    <!-- <button
                        v-else
                        :class="`btn btn__white ${e.type} ${currentPlaceholder === eIndex ? 'vertical-line':''}`"
                    >&nbsp;</button> -->
                    <button
                        v-else
                        :class="`btn btn__white ${e.type} ${currentQuestionIndex === e.optionsGroupsIndex ? 'vertical-line':''}`"
                    >&nbsp;</button>
                </span>
                <button v-else :class="`btn btn__white  ${e.type}`">
                    {{ e.expression }}
                </button>
            </span>
          </div>
      </div>
  
      <div class="btn__options">
        <div
          v-for="(o, oIndex) in options"
          :key="oIndex"
        >
          <button
            :class="`btn btn__${o.btn} text-white`"
            @click="handleOptionClick(o, oIndex)"
          >
            {{ o.option }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BButton, BAlert, BSpinner } from "bootstrap-vue";
//   import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
//   import Confetti from "../../../../public/Confetti.json";
//   import Lottie from "vue-lottie";
  import _ from "lodash";
  import * as Helpers from "@/Helpers";
  
export default {
    components: {
        BButton,
        BAlert,
        BSpinner,
        // ToastificationContent,
        // Lottie,
    },
    data() {
        return {
            // questionText: "",
            // alertMessageToast: "",
            // Confetti,
            // confettiOptions: {
            //     loop: false,
            //     autoplay: true,
            //     animationData: Confetti,
            // },
            // draggedOption: null,
            classes: ["blue", "green", "orange", "purple"],
            dragging: false,
            expressions: [],
            options: [],
            answers: [],
            Helpers,
            currentPlaceholder: 0,
            currentQuestionIndex: 0,
        };
    },
    methods: {
        // activeRemainingExpression() {
        //     const firstUnansweredQuestion = this.expressions.find(
        //         (exp) => exp.type === "question" && exp.expression === "__"
        //     );

        //     if(firstUnansweredQuestion != undefined){
        //         this.currentQuestionIndex = firstUnansweredQuestion.optionsGroupsIndex;
        //         this.getOptions();
        //     }
        //     console.log('firstUnansweredQuestion=',JSON.stringify(firstUnansweredQuestion));
        // },
        activeRemainingExpression() {
            // Filter to get all unanswered questions, then select the first one if available
            const unansweredQuestions = this.expressions.filter(
                (exp) => exp.type === "question" && exp.expression === "__"
            );

            // If there are any unanswered questions, select the first one
            if (unansweredQuestions.length > 0) {
                const firstUnansweredQuestion = unansweredQuestions[0];
                
                // Update currentQuestionIndex and call getOptions based on the first unmatched question
                this.currentQuestionIndex = firstUnansweredQuestion.optionsGroupsIndex;
                this.getOptions();
                
                console.log('firstUnansweredQuestion=', JSON.stringify(firstUnansweredQuestion));
            }
        },
        selectQuestion(e) {
            // if(e.expression != '__'){
            //     return;
            // }
            console.log('selectQuestion e=', e);
            this.currentQuestionIndex = e.optionsGroupsIndex;
            this.getOptions();
        },
        getExpressions() {
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].expression) {
                const expression = this.currentQuestion.patterns[0].expression;
                const expressionArray = expression.split(",");

                let optionsGroupsCounter = 0;
                this.expressions = expressionArray.map((element, index) => {
                    const isQuestion = element === "__";
                    
                    return {
                        expression: element,
                        type: isQuestion ? "question" : "option",
                        optionsGroupsIndex: isQuestion ? optionsGroupsCounter++ : null,
                    };
                });

                this.answers = this.Helpers.splitValueByCommaAccordingToExpression(this.expressions, this.currentQuestion.patterns[0].answer);
            
                this.currentPlaceholder = this.expressions.findIndex(
                    (e) => e.type === "question" && !e.matched
                );
            }
        },
        getOptions() {
            console.log('getOptions this.currentQuestionIndex=', this.currentQuestionIndex);
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].optionsGroups && this.currentQuestion.patterns[0].optionsGroups.length > 0) {
                const optionsTitle = this.currentQuestion.patterns[0].optionsGroups[this.currentQuestionIndex].title;
                const optionsArray = optionsTitle.split(",").map(option => option.trim());

                this.options = _.shuffle(optionsArray).map((option, index) => ({
                    option,
                    btn: this.classes[index % this.classes.length],
                }));
            }
        },

        handleOptionClick(option, optionIndex) {
            const questionIndex = this.expressions.findIndex(
                // (e) => e.type === "question" && !e.matched
                (e) => e.optionsGroupsIndex === this.currentQuestionIndex
            );
            
            // If there is a question slot available
            if (questionIndex !== -1) {

                // Fill the expression slot
                this.$set(this.expressions, questionIndex, {
                    ...this.expressions[questionIndex],
                    expression: option.option,
                    matched: true,
                    btn: option.btn,
                });

                // Update the next placeholder position
                this.currentPlaceholder = this.expressions.findIndex(
                    (e) => e.type === "question" && !e.matched
                );

                const allFilled = this.expressions.every((e) => e.type !== "question" || e.matched);
                console.log('this.expressions=',this.expressions, allFilled)

                if (allFilled) {
                    // Validate if all answers are correct
                    const isCorrect = this.expressions.every((e, index) => 
                        e.expression === this.answers[index].expression
                    );

                    const getEmit = { ...this.currentQuestion, isCorrect };

                    setTimeout(() => {
                        if (!isCorrect) {
                            this.getOptions();

                            this.expressions.forEach((e, index) => {
                                if (e.type === "question") {
                                    this.$set(this.expressions, index, {
                                        ...e,
                                        expression: "__",
                                        matched: false,
                                        // btn: 'question',
                                    });
                                }
                            });

                            this.currentPlaceholder = this.expressions.findIndex(
                                (e) => e.type === "question" && !e.matched
                            );

                            this.activeRemainingExpression();
                        } else {
                            this.currentQuestionIndex = 0;
                        }
                    }, 500);

                    console.log('getEmit=',getEmit)
                    this.$emit("get-question-response", getEmit);
                }
            }

            this.activeRemainingExpression();

            // const countQuestionsWithUnderscore = this.expressions.filter(
            //     (e) => e.type === "question" && e.expression === "__"
            // ).length;

            // if(countQuestionsWithUnderscore > 0){
            //     console.log('this.currentQuestionIndex=',this.currentQuestionIndex);
            //     this.currentQuestionIndex++;
            //     this.getOptions();
            // } else {
            //     this.currentQuestionIndex = 0;
            // }
        }

    },
    watch: {
        currentQuestion() {
            this.getExpressions();
            this.getOptions();
            this.currentQuestionIndex = 0;
        },
    },
    mounted() {
        this.getExpressions();
        this.getOptions();
        this.currentQuestionIndex = 0;
    },
    props: ["currentQuestion"],
};
</script>

<style>
.missing-elements .btn__expressions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: clamp(1.25rem, 2vw, 2rem);
    justify-content: center;
}
.missing-elements .btn__expressions .btn {
    min-width: clamp(4rem, 8vw, 6rem);
    min-height: clamp(4rem, 8vw, 6rem);

    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #0D5CAB;
}
.missing-elements .btn__expressions .btn.btn__white {
    background: #fff;
}
.missing-elements .btn__expressions .btn.btn__white.option {
    color: #ff0000;
}
.missing-elements .btn__expressions .btn.btn__white.question {
    color: #000;
}
.missing-elements .btn__options {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}
.missing-elements .btn__options .btn {
    min-width: clamp(4rem, 8vw, 5rem);
    min-height: clamp(4rem, 8vw, 5rem);

    display: flex;
    align-items: center;
    justify-content: center;
}
.missing-elements .vertical-line {
    position: relative;
}
.missing-elements .vertical-line:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: clamp(3rem, 8vw, 4rem);
    width: 2px;
    background: #000;
}
.missing-elements .vertical-line:before,
.missing-elements .blinking-cursor {
    animation: blink 1s infinite;
}
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>